import React from "react";
import { Link, Typography, Box, styled } from "@mui/material";
import { Translate } from "react-translated";
import { useDispatch } from "react-redux";
import { setModalState } from "../../features/modalStateSlice";

const HaveAccountBox = styled(Box)({
  maxWidth: "656px",
  flex: "0 0 auto",
  width: "100%",
});

export const HaveAccount = () => {
  const dispatch = useDispatch();
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <HaveAccountBox>
      <Typography
        sx={{
          fontSize: {
            xs: "16px",
            sm: "18px",
          },
          color: "primary.main",
          fontWeight: "400",
          lineHeight: "26px",
        }}
        textAlign="center"
        display="block"
      >
        <Translate text={`have account_${dynamicType}`} />
        <Link
          color="primary.orange"
          onClick={() =>
            dispatch(
              setModalState({
                isOpen: true,
                type: "login",
                isOpenSmallModal: false,
              })
            )
          }
          sx={{ textDecoration: "none", cursor: "pointer" }}
        >
          {" "}
          <Translate text={`log In_${dynamicType}`} />
        </Link>
      </Typography>
    </HaveAccountBox>
  );
};
