import extraEmailData_1 from "./../images/extraEmailData_1.png";
import extraEmailData_2 from "./../images/extraEmailData_2.png";
import extraEmailData_3 from "./../images/extraEmailData_3.png";
import extraEmailData_4 from "./../images/extraEmailData_4.png";

export const funMessageData = [
  "funMessageDataText_1",
  "funMessageDataText_2",
  "funMessageDataText_3",
  "funMessageDataText_4",
  "funMessageDataText_5",
  "funMessageDataText_6",
];

export const questionData = [
  "questionData_1",
  "questionData_2",
  "questionData_3",
  "questionData_4",
  "questionData_5",
  "questionData_6",
];

export const messageWithImgData = [
  "messageWithImgData_1",
  "messageWithImgData_2",
  "messageWithImgData_3",
  "messageWithImgData_4",
  "messageWithImgData_5",
];

export const extraEmailData = [
  {
    id: 0,
    text: "extraEmailData_1",
    iconName: extraEmailData_1,
  },
  {
    id: 1,
    text: "extraEmailData_2",
    iconName: extraEmailData_2,
  },
  {
    id: 2,
    text: "extraEmailData_3",
    iconName: extraEmailData_3,
  },
  {
    id: 3,
    text: "extraEmailData_4",
    iconName: extraEmailData_4,
  },
];

export const extraLocationData = [
  {
    id: 1,
    btName: "extraLocationData_1",
  },
  {
    id: 2,
    btName: "extraLocationData_2",
  },
  {
    id: 3,
    btName: "extraLocationData_3",
  },
  {
    id: 4,
    btName: "extraLocationData_4",
  },
];
