import React from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  useTheme,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styled from "@emotion/styled";
import { DialogContentComp } from "../dialog/dialog_components/DialogContentComp";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "../../features/modalStateSlice";

const DialogCloseBtn = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: "700",
  padding: "6px",
  marginTop: "16px",

  [theme.breakpoints.up("sm")]: {
    padding: "4px",
  },
}));

const DialogContentStyle = styled(DialogContent)(({ theme }) => ({
  userSelect: "none",
  fontWeight: "400",
  color: "rgb(99, 115, 129)",
  padding: "0",

  [theme.breakpoints.up("sm")]: {
    padding: "0 41px",

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cacaca",
      border: "4px solid transparent",
      borderRadius: "8px",
      backgroundClip: "padding-box",
    },

    "&::-webkit-scrollbar": {
      width: "16px",
    },
  },
}));

export const ModalComp = () => {
  const modalState = useSelector((state) => state.modalState);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dialogWidth = isSmScreen
    ? "100%"
    : modalState.type === "terms" || modalState.type === "privacy"
    ? "94%"
    : "566px";

  const handleClose = () => {
    if (modalState.type === "terms" || modalState.type === "privacy") {
      dispatch(
        setModalState({ isOpen: true, type: "signup", isOpenSmallModal: false })
      );
    } else if (modalState.previousState) {
      dispatch(setModalState({ isOpen: true, type: modalState.previousState }));
    } else {
      dispatch(
        setModalState({
          isOpen: false,
          type: "",
          previousState: "",
          isOpenSmallModal: false,
        })
      );
    }
  };

  return (
    <Dialog
      onClick={(e) => {
        e.stopPropagation();
      }}
      open={modalState.isOpen}
      onClose={handleClose}
      fullWidth
      fullScreen={isSmScreen}
      maxWidth={
        modalState.type === "terms" || modalState.type === "privacy"
          ? false
          : "sm"
      }
      style={{ width: dialogWidth, margin: "0 auto" }}
      PaperProps={{
        sx: {
          borderRadius: {
            xs: "0px",
            sm: "8px",
          },
          my: 2,
          pb: 4,
          height: {
            xs: "100%",
            sm: "max-content",
          },
          width: {
            xs: "100%",
          },
        },
      }}
    >
      <DialogCloseBtn
        sx={{
          justifyContent:
            modalState.type === "terms" || modalState.type === "privacy"
              ? "flex-start"
              : "flex-end",
        }}
      >
        <IconButton onClick={handleClose} sx={{ color: "primary.grey" }}>
          {modalState.type === "terms" || modalState.type === "privacy" ? (
            <ArrowBackIcon sx={{ fontSize: "24px" }} />
          ) : (
            <CloseIcon sx={{ fontSize: "24px" }} />
          )}
        </IconButton>
      </DialogCloseBtn>

      <DialogContentStyle>
        <DialogContentComp type={modalState.type} />
      </DialogContentStyle>
    </Dialog>
  );
};
