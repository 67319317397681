import React from "react";
import { DialogParagraph } from "./dialog_components/DialogParagraph";
import { DialogSubtitle } from "./dialog_components/DialogSubtitle";
import { DialogBoldWithRegularText } from "./dialog_components/DialogBoldWithRegularText";
import { Link, Typography, Box } from "@mui/material";
import { Translate } from "react-translated";
import { DialogUnorderList } from "./dialog_components/DialogUnorderList";
import { DialogBoldText } from "./dialog_components/DialogBoldText";
import silvanus from "./text_svg/SilvanusTechnologiesLtd.svg";
import hE434459 from "./text_svg/HE434459.svg";
import archiepiskopou_1 from "./text_svg/ArchiepiskopouMakariouIll_1.svg";
import archiepiskopou_2 from "./text_svg/ArchiepiskopouMakariouIll_2.svg";
import { DialogImagSVG } from "./dialog_components/DialogImagSVG";
import supportintimflortsSVG from "./text_svg/support@intimflorts.com.svg";
import silvanusBold from "./text_svg/SilvanusTechnologiesLtdBold.svg";
import {
  automaticallyGeneratedData,
  categoriesPersonalData1,
  categoriesPersonalData2,
  categoriesPersonalData3,
  categoriesPersonalData4,
  categoriesPersonalData5,
  categoriesPersonalData6,
  categoriesPersonalData7,
  yourAccountData,
  yourRightsData,
} from "../../dataUi/dialogData";

export const PrivacyStatementDialog = () => {
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{ padding: { xs: "0 16px 24px", sm: "0" } }}
      onContextMenu={handleContextMenu}
    >
      <Typography
        variant="Subtitle3"
        sx={{
          mb: "12px",
          display: "block",
          fontWeight: "600",
        }}
      >
        <Translate text="Privacy Statement" />
      </Typography>
      <DialogParagraph text="privacy_statement_content_1" />
      <DialogSubtitle text="privacy_statement_content_2" />
      <Typography variant="Body5" paragraph sx={{ mb: 1.5 }}>
        <Typography variant="span">
          <Translate text={`privacy_statement_content_3`} />
        </Typography>{" "}
        <DialogBoldText text={`privacy_statement_content_3b`} />
      </Typography>
      <DialogBoldWithRegularText num={4} />
      <DialogBoldWithRegularText num={5} />
      <DialogBoldWithRegularText num={6} />
      <Typography variant="Body5" paragraph sx={{ mb: 1 / 2 }}>
        <DialogImagSVG src={silvanusBold} sx={{ top: "3px" }} />{" "}
        <Typography variant="Body5" component="span" sx={{ mb: "6px" }}>
          <Translate text="privacy_statement_content_7_1" />{" "}
          <DialogImagSVG src={hE434459} sx={{ margin: "0 2px" }} />,{" "}
          <Translate text="privacy_statement_content_7_2" />{" "}
          <DialogImagSVG src={archiepiskopou_1} sx={{ top: "3px" }} />{" "}
          <DialogImagSVG src={archiepiskopou_2} sx={{ top: "3px" }} />,{" "}
          <Translate text="privacy_statement_content_7_3" />{" "}
        </Typography>
      </Typography>
      <DialogBoldWithRegularText num={8} />
      <DialogBoldWithRegularText num={9} />
      <DialogBoldWithRegularText num={10} />
      <DialogBoldWithRegularText num={11} />
      <DialogBoldWithRegularText num={12} />
      <DialogBoldWithRegularText num={13} />
      <DialogParagraph text="privacy_statement_content_13_1" />
      <DialogSubtitle text="privacy_statement_content_14" />
      <DialogParagraph text="privacy_statement_content_15" />
      <DialogParagraph text="privacy_statement_content_16" />
      <ul style={{ marginTop: "20px", paddingLeft: "40px", fontSize: "14px" }}>
        <Typography variant="Body5" component="li" sx={{ mb: "6px" }}>
          <Translate text="privacy_statement_content_17_1" />{" "}
          <DialogImagSVG src={silvanus} sx={{ top: "3px" }} />{" "}
          <Translate text="privacy_statement_content_17_2" />{" "}
          <DialogImagSVG src={hE434459} sx={{ margin: "0 2px" }} />{" "}
          <Translate text="privacy_statement_content_17_3" />{" "}
          <DialogImagSVG src={archiepiskopou_1} sx={{ top: "3px" }} />{" "}
          <DialogImagSVG src={archiepiskopou_2} sx={{ top: "3px" }} />{" "}
          <Translate text="privacy_statement_content_17_4" />
        </Typography>
      </ul>
      <DialogUnorderList arrayData={yourAccountData} sx={{ mt: 0 }} />
      <DialogSubtitle text="privacy_statement_content_21" />
      <DialogParagraph text="privacy_statement_content_22" />
      <DialogParagraph text="privacy_statement_content_23" />
      <DialogSubtitle text="privacy_statement_content_24" />
      <DialogParagraph text="privacy_statement_content_25" />
      <DialogParagraph text="privacy_statement_content_26" />
      <DialogParagraph text="privacy_statement_content_27" />
      <DialogParagraph text="privacy_statement_content_28" />
      <DialogSubtitle text="privacy_statement_content_29" />
      <DialogParagraph text="privacy_statement_content_30" />
      <DialogSubtitle text="privacy_statement_content_31" />
      <DialogParagraph text="privacy_statement_content_32" />
      <DialogUnorderList
        arrayData={automaticallyGeneratedData}
        sx={{ marginTop: "20px" }}
      />
      <DialogSubtitle text="privacy_statement_content_39" />
      <DialogParagraph text="privacy_statement_content_40" />
      <DialogParagraph text="privacy_statement_content_41" />
      <DialogParagraph text="privacy_statement_content_42" />
      <DialogUnorderList
        arrayData={yourRightsData}
        sx={{ marginTop: "20px" }}
      />
      <Link
        variant="Body5"
        target="_blank"
        href="https://intimflorts.com/static/erasure.pdf"
        sx={{
          textDecoration: "none",
          marginBottom: "20px",
          paddingLeft: "40px",
          display: "inline-block",
          color: "primary.darkGrey",
        }}
      >
        <Translate text="privacy_statement_content_46_1" />
      </Link>
      <DialogParagraph text="privacy_statement_content_47" />
      <Link
        variant="Body5"
        target="_blank"
        href="https://www.dataprotection.gov.cy/dataprotection/dataprotection.nsf/home_el/home_el?opendocument"
        sx={{
          textDecoration: "none",
          marginBottom: "20px",
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
          color: "primary.darkGrey",
        }}
      >
        (https://www.dataprotection.gov.cy/dataprotection/dataprotection.nsf/home_el/home_el?opendocument)
      </Link>
      <DialogSubtitle text="privacy_statement_content_48" />
      <DialogParagraph text="privacy_statement_content_49" />
      <DialogSubtitle text="privacy_statement_content_50" />
      <DialogParagraph text="privacy_statement_content_51" />
      <DialogParagraph text="privacy_statement_content_52" />
      <DialogBoldText text="privacy_statement_content_53" />
      <DialogParagraph text="privacy_statement_content_54" />
      <DialogParagraph text="privacy_statement_content_55" />
      <DialogUnorderList
        arrayData={categoriesPersonalData1}
        sx={{ margin: "20px 0" }}
      />
      <DialogParagraph text="privacy_statement_content_64" />
      <DialogUnorderList
        arrayData={categoriesPersonalData2}
        sx={{ margin: "20px 0" }}
      />
      <DialogBoldText text="privacy_statement_content_68" />
      <DialogParagraph text="privacy_statement_content_69" />
      <DialogParagraph text="privacy_statement_content_70" />
      <DialogUnorderList
        arrayData={categoriesPersonalData3}
        sx={{ margin: "20px 0" }}
      />
      <DialogParagraph text="privacy_statement_content_79" />
      <DialogUnorderList
        arrayData={categoriesPersonalData4}
        sx={{ margin: "20px 0" }}
      />
      <DialogBoldText text="privacy_statement_content_86" />
      <DialogParagraph text="privacy_statement_content_87" />
      <DialogParagraph text="privacy_statement_content_88" />
      <DialogUnorderList
        arrayData={categoriesPersonalData5}
        sx={{ margin: "20px 0" }}
      />
      <DialogParagraph text="privacy_statement_content_96" />
      <DialogUnorderList
        arrayData={categoriesPersonalData6}
        sx={{ margin: "20px 0" }}
      />
      <DialogParagraph text="privacy_statement_content_100" />
      <DialogUnorderList
        arrayData={categoriesPersonalData7}
        sx={{ margin: "20px 0" }}
      />
      <DialogSubtitle text="privacy_statement_content_104" />
      <Typography
        variant="Body5"
        sx={{
          mt: 1 / 2,
          mb: 1,
        }}
      >
        <Translate text="privacy_statement_content_105" />
      </Typography>{" "}
      <DialogImagSVG src={supportintimflortsSVG} sx={{ top: "3px" }} />
    </Box>
  );
};
