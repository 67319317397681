import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Input } from "../common/Input";
import { ErrorMessage } from "../common/ErrorMessage";
import { ButtonNext } from "../common/ButtonNext";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { next } from "../../features/activeStepSlice";
import { useDebounce } from "../../hooks/useDebounce";
import { Translate } from "react-translated";

export const Username = () => {
  const userData = useSelector((state) => state.userData?.value);
  const [username, setUsername] = useState(userData.username || "");
  const apiUrl = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const regex = /^[_&?!#0-9a-zA-Z\u00c0-\u017e]{3,12}$/;
  const message = "usernameErrMessFE";
  const useDebounceValue = useDebounce(username, 500, regex, message);
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length < 13) {
      setUsername(value);
    }
    dispatch(addErrorMessage(""));
  };

  useEffect(() => {
    if (useDebounceValue || !errorMessage) {
      dispatch(addField({ username: useDebounceValue }));
    } else {
      dispatch(addField({ username: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDebounceValue]);

  const fetchData = async () => {
    try {
      const response = await apiUrl.post(
        `/registration/start`,
        {
          username: useDebounceValue,
          recaptcha_token: null,
        },
        {
          params: {
            site_key: "no01",
          },
        }
      );
      if (response?.data.Status === "ok") {
        sessionStorage.setItem("user_id", response?.data.Data);
        dispatch(next());
        dispatch(addErrorMessage(""));
      }
    } catch (error) {
      if (error?.response?.data.Status === "fail") {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
        localStorage.removeItem("uid");
      }
    }
  };

  const handleNext = () => {
    if (useDebounceValue) {
      fetchData();
    }
  };

  return (
    <Box className="userBox">
      <Input
        name="username"
        value={username}
        handleChange={handleChange}
        placeholder="usernamePlaceholder"
        typeInput="text"
        isValid={!useDebounceValue || !!errorMessage}
        autoFocus={true}
      />

      {!errorMessage ? (
        <Typography
          variant="Body4"
          display="block"
          sx={{
            marginTop: "10px",
            color: "primary.grey",
            fontSize: { sm: "14px" },
            lineHeight: { xs: "20px" },
          }}
        >
          <Translate text={`username text_${dynamicType}`} />
        </Typography>
      ) : (
        <ErrorMessage errorMessage={errorMessage} />
      )}

      <ButtonNext
        onClick={handleNext}
        text="Next"
        style={{ cursor: useDebounceValue ? "pointer" : "no-drop" }}
        disabled={!useDebounceValue || !!errorMessage}
      />
    </Box>
  );
};
