import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { ImageComp } from "../common/ImageComp";

export const ExtraUsername = () => {
  const userData = useSelector((state) => state.userData?.value);
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      {userData.username && !errorMessage ? (
        <ImageComp
          src={require(`./../../images/extraAgeValid_${dynamicType}.png`)}
          sx={{ width: "100%", height: { xs: "270px", sm: "auto" } }}
        />
      ) : (
        <ImageComp
          src={require(`./../../images/extraAge_${dynamicType}.png`)}
          sx={{ width: "100%", height: { xs: "270px", sm: "auto" } }}
        />
      )}
    </Box>
  );
};
