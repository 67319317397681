import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { Translate } from "react-translated";

const ExtraLocationCartBox = styled(Box)(({ theme, imgsrc }) => ({
  backgroundImage: `url(${imgsrc})`,
  width: "90px",
  height: "90px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  display: "flex",
  justifyContent: "center",
  alignItems: "end",
  cursor: "pointer",
  marginLeft: "16px",

  [theme.breakpoints.up("sm")]: {
    width: "132px",
    height: "132px",
    marginLeft: "12px",
  },
}));

const ExtraLocationCartTypography = styled(Typography)(({ theme }) => ({
  margin: "4px",
  background: "white",
  width: "100%",
  opacity: "0.7",
  borderRadius: "8px",
  textAlign: "center",
  height: "22px",
  overflow: "hidden",
  fontSize: "11px",

  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
    margin: "8px",
    height: "24px",
  },
}));

export const ExtraLocationCart = ({ imgSrc, btName, onClick }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <ExtraLocationCartBox onClick={onClick} imgsrc={imgSrc}>
      <ExtraLocationCartTypography variant="Body4">
        <Translate text={`${btName}_${dynamicType}`} />
      </ExtraLocationCartTypography>
    </ExtraLocationCartBox>
  );
};
