import React from "react";
import { Box } from "@mui/material";
import { CarouselComp } from "../common/CarouselComp";

export const ExtraGender = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
      }}
    >
      <CarouselComp />
    </Box>
  );
};
