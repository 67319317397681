import React from "react";
import { Typography } from "@mui/material";
import { Translate } from "react-translated";

export const DialogBoldText = ({ text, sx }) => {
  return (
    <Typography variant="Body5" sx={{ ...sx, fontWeight: "600" }}>
      <Translate text={text} />
    </Typography>
  );
};
