import { useState, useEffect, useRef, useCallback } from "react";
import { Box, styled } from "@mui/material";

const LooperBox = styled(Box)(() => ({
  width: "100%",
  overflow: "hidden",
}));

const LooperInnerListBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  width: "fit-content",
  marginBottom: "4px",
}));

const LooperListInstanceBox = styled(Box)(() => ({
  display: "flex",
  width: "max-content",
  animation: "none",
}));

export const InfiniteLooper = ({ speed, direction, children }) => {
  const [looperInstances, setLooperInstances] = useState(1);
  const outerRef = useRef(null);
  const innerRef = useRef(null);

  const resetAnimation = () => {
    if (innerRef?.current) {
      innerRef.current.setAttribute("data-animate", "false");

      setTimeout(() => {
        if (innerRef?.current) {
          innerRef.current.setAttribute("data-animate", "true");
        }
      }, 10);
    }
  };

  const setupInstances = useCallback(() => {
    if (!innerRef?.current || !outerRef?.current) return;
    const { width } = innerRef.current.getBoundingClientRect();
    const { width: parentWidth } = outerRef.current.getBoundingClientRect();
    const widthDeficit = parentWidth - width;
    const instanceWidth = width / innerRef.current.children.length;

    if (widthDeficit) {
      setLooperInstances(
        looperInstances + Math.ceil(widthDeficit / instanceWidth) + 1
      );
    }

    resetAnimation();
  }, [looperInstances]);

  useEffect(() => setupInstances(), [setupInstances]);

  useEffect(() => {
    window.addEventListener("resize", setupInstances);

    return () => {
      window.removeEventListener("resize", setupInstances);
    };
  }, [looperInstances, setupInstances]);

  return (
    <LooperBox ref={outerRef}>
      <LooperInnerListBox
        ref={innerRef}
        data-animate="true"
        className="looper__innerList"
      >
        {[...Array(looperInstances)].map((_, ind) => (
          <LooperListInstanceBox
            className="looper__listInstance"
            key={ind}
            style={{
              animationDuration: `${speed}s`,
              animationDirection: direction === "right" ? "reverse" : "normal",
            }}
          >
            {children}
          </LooperListInstanceBox>
        ))}
      </LooperInnerListBox>
    </LooperBox>
  );
};
