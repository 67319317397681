import React from "react";
import { Box, styled } from "@mui/material";
import { HomeUserIconText } from "./HomeUserIconText";
import { homeUserIconsData } from "../../../dataUi/homeUserCartData";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";

const HomeUserIconsBox = styled(Box)(({ theme }) => ({
  margin: "12px 16px",
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.up("sm")]: {
    margin: "14px 12px 24px",
  },
}));

export const HomeUserIcons = () => {
  return (
    <HomeUserIconsBox>
      <Box sx={{ display: "flex" }}>
        {homeUserIconsData?.map((item) => (
          <HomeUserIconText
            key={item.id}
            srcImg={item.srcImg}
            text={item.text}
            Icon={item.Icon}
            sx={{
              icon: {
                color: "primary.grey",
                maxWidth: "32px",
                maxHeight: "32px",
                fontSize: "32px",
              },
              text: {
                color: "primary.grey",
                marginLeft: "4px",
              },
            }}
          />
        ))}
      </Box>
      <TurnedInNotIcon
        sx={{ color: "#B2B3B5", width: "32px", height: "32px" }}
      />
    </HomeUserIconsBox>
  );
};
