import React from "react";
import { ButtonNext } from "../common/ButtonNext";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { next } from "../../features/activeStepSlice";
import { FormControlComp } from "../common/FormControlComp";
import { getOptionGenderValue } from "../../helpers/DateUtils";

export const Gender = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData?.value);

  const handleChange = (e) => {
    dispatch(addField({ gender: e.target.value }));
  };

  const handleNext = () => {
    if (userData.gender) {
      dispatch(
        addField({
          looking_for: userData.gender === "Male" ? "Female" : "Male",
        })
      );
      dispatch(next());
    }
  };

  return (
    <>
      <FormControlComp
        value={userData.gender || ""}
        handleChange={handleChange}
        label="gender"
        name="gender"
        getOption={getOptionGenderValue}
        labelId="gender-label"
        id="gender-select"
        sx={{ width: "100%", boxSizing: "border-box" }}
      />

      <ButtonNext
        onClick={handleNext}
        text="Next"
        disabled={!userData.gender}
      />
    </>
  );
};
