import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Translate } from "react-translated";

export const CeckBoxWithText = ({ text }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Box sx={{ display: "flex", mb: 1 }}>
      <CheckIcon sx={{ color: "primary.green", mr: 1 }} />
      <Typography
        variant={isSmallScreen ? "Body3" : "Body2"}
        sx={{ color: "primary.main" }}
      >
        <Translate text={`${text}_${dynamicType}`} />
      </Typography>
    </Box>
  );
};
