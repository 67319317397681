import React from "react";

export const ButtonImg = ({ imgSrc, onClick, sx = {} }) => {
  return (
    <button
      onClick={onClick}
      style={{
        minWidth: "40px",
        height: "40px",
        padding: "0",
        boxShadow: "0px 2px 4px 0px #00000014",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
        background: "none",
        borderRadius: "8px",
        cursor: "pointer",
        marginLeft: "12px",
        ...sx,
      }}
    >
      <img src={imgSrc} alt="imgIcon" />
    </button>
  );
};
