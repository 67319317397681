import React from "react";
import { Box, Typography } from "@mui/material";
import { Translate } from "react-translated";

export const ExtraEmailCart = ({ iconName, text }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Box
      sx={{
        boxShadow: "0px 2px 4px 0px #00000014",
        height: "80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "48%",
        mb: 2,
        borderRadius: 1,
        color: "primary.main",
      }}
    >
      <img src={iconName} alt={iconName} />
      <Typography variant="Body4" sx={{ mt: 1, fontSize: { sm: "14px" } }}>
        <Translate text={`${text}_${dynamicType}`} />
      </Typography>
    </Box>
  );
};
