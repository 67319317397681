import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { HomeUserName } from "./HomeUserName";
import { Translate } from "react-translated";
import { HomeUserIcons } from "./HomeUserIcons";
import { HomeUserComments } from "./HomeUserComments";
import { ImageComp } from "../../common/ImageComp";

const HomeUserPostCartBox = styled(Box)(({ theme }) => ({
  margin: "0 16px",
  display: "flex",
  alignItems: "flex-start",

  [theme.breakpoints.up("sm")]: {
    margin: "0 12px",
  },
}));

const HomeUserPostCartTypography = styled(Typography)(({ theme }) => ({
  margin: "4px 16px 10px",
  color: `${theme.palette.primary.main}`,
  display: "inline-block",

  [theme.breakpoints.up("sm")]: {
    margin: "16px 12px 14px",
  },
}));

export const HomeUserPostCart = ({ text, src }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Box>
      <HomeUserPostCartBox>
        <ImageComp
          src={require(`./../images/homeUserCart_${dynamicType}.png`)}
          sx={{
            width: { xs: "48px", sm: "56px" },
            height: { xs: "48px", sm: "56px" },
          }}
        />
        <HomeUserName
          staticText={true}
          sx={{
            wrapperBox: {
              margin: { xs: "0 12px", sm: "0 16px" },
            },
          }}
        />
      </HomeUserPostCartBox>
      <HomeUserPostCartTypography variant="Body2">
        <Translate text={text} />
      </HomeUserPostCartTypography>
      <ImageComp
        src={src}
        sx={{
          width: { xs: "100%", sm: "100%" },
          height: { xs: "375px", sm: "502px" },
          objectFit: "cover",
        }}
      />
      <HomeUserIcons />
      <HomeUserComments />
    </Box>
  );
};
