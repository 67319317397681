export const mainContent = {
  //Home component
  "home_content_1_casual": {
    en: `@seen on 27 Sep`,
    no: `@ sist pålogger 3 min side`,
  },
  "home_content_1_mainstream": {
    en: `@seen on 27 Sep`,
    no: `@ sist pålogger 3 min side`,
  },
  "home_content_2_casual": {
    en: `London, England`,
    no: `Oslo, Norge`,
  },
  "home_content_2_mainstream": {
    en: `London, England`,
    no: `Oslo, Norge`,
  },
  "home_content_3_casual": {
    en: `Hi, I'm an adventurous girl in my twenties from Spain, with a playful and slightly naughty side. I love to explore – whether it's hidden trails in nature or new depths of pleasure. Photography is my passion, and I like to capture beauty in all its forms. Looking for someone who is as comfortable on a mountain hike as they are under silk sheets. Subscribe if you dare to keep up with both daring adventures and intimate evenings. 🔥📸💋`,
    no: `Hei, jeg er en eventyrlysten jente i 20-årene fra Oslo, med en leken og litt frekk side. Jeg elsker å utforske – enten det er skjulte stier i naturen eller nye dybder av nytelse. Fotografering er min lidenskap, og jeg liker å fange skjønnheten i alle former. Leter etter noen som er like komfortabel på et fjelltur som under silkelakene. Abonner hvis du tør å holde følge på både vågale eventyr og intime kvelder. 🔥📸💋`,
  },
  "home_content_3_mainstream": {
    en: `Hi, I'm an adventurous girl in my twenties from Spain, with a playful and slightly naughty side. I love to explore – whether it's hidden trails in nature or new depths of pleasure. Photography is my passion, and I like to capture beauty in all its forms. Looking for someone who is as comfortable on a mountain hike as they are under silk sheets. Subscribe if you dare to keep up with both daring adventures and intimate evenings. 🔥📸💋`,
    no: `Hei, jeg er en eventyrlysten spansk jente i tjueårene med en leken side. Jeg elsker natur og fotografering. Leter etter noen som trives både på fjelltur og under silkelaken. Abonner for eventyr og intime kvelder. 🔥📸💋`,
  },
  "home_content_4_casual": {
    en: `View all comments`,
    no: `Se alle kommentarer`,
  },
  "home_content_4_mainstream": {
    en: `View all comments`,
    no: `Se alle kommentarer`,
  },
  "home_content_5_casual": {
    en: `Account`,
    no: `Konto`,
  },
  "home_content_5_mainstream": {
    en: `Account`,
    no: `Konto`,
  },
  "home_content_6_casual": {
    en: `Explore`,
    no: `Utforsk`,
  },
  "home_content_6_mainstream": {
    en: `Explore`,
    no: `Utforsk`,
  },
  "home_content_7_casual": {
    en: `Home`,
    no: `Hjem`,
  },
  "home_content_7_mainstream": {
    en: `Home`,
    no: `Hjem`,
  },
  "home_content_8_casual": {
    en: `Notifications`,
    no: `Varsler`,
  },
  "home_content_8_mainstream": {
    en: `Notifications`,
    no: `Varsler`,
  },
  "home_content_9_casual": {
    en: `Messages`,
    no: `Meldinger`,
  },
  "home_content_9_mainstream": {
    en: `Messages`,
    no: `Meldinger`,
  },
  // Suggest registration
  "suggest_registration_1_casual": {
    en: `SUBSCRIBE AND GET THESE BENEFITS:`,
    no: `ABONNER OG FÅ DISSE FORDELENE:`,
  },
  "suggest_registration_1_mainstream": {
    en: `SUBSCRIBE AND GET THESE BENEFITS:`,
    no: `ABONNER OG FÅ DISSE FORDELENE:`,
  },
  "suggest_registration_2_casual": {
    en: `Full access to this user's content`,
    no: `Full tilgang til denne brukerens innhold`,
  },
  "suggest_registration_2_mainstream": {
    en: `Full access to this user's content`,
    no: `Ubegrenset tilgang til alt innholdet til denne brukeren`,
  },
  "suggest_registration_3_casual": {
    en: `Direct messages with this user`,
    no: `Direktemeldinger med denne brukeren`,
  },
  "suggest_registration_3_mainstream": {
    en: `Direct messages with this user`,
    no: `Mulighet for direkte meldinger til denne brukeren`,
  },
  "suggest_registration_4_casual": {
    en: `No monthly costs`,
    no: `Uten månedlige utgifter`,
  },
  "suggest_registration_4_mainstream": {
    en: `No monthly costs`,
    no: `Ingen månedlige kostnader`,
  },
  "suggest_registration_5_casual": {
    en: `This website is for adults only`,
    no: `Dette nettstedet er kun for voksne`,
  },
  "suggest_registration_5_mainstream": {
    en: `This website is for adults only`,
    no: `Nettsiden er eksklusivt for voksne`,
  },
  "suggest_registration_6_casual": {
    en: `Are you over 18 years old?`,
    no: `Er du over 18 år gammel?`,
  },
  "suggest_registration_6_mainstream": {
    en: `Are you over 18 years old?`,
    no: `Er du over 18 år?`,
  },
    // Have an account component content
  "have account_casual": {
    en: `Already have an account?`,
    no: `Allerede en konto`,
  },
  "have account_mainstream": {
    en: `Do you already have an account?`,
    no: `Har du allerede en konto?`,
  },
  "log In_casual": {
    en: `Log In`,
    no: `Logg Inn`,
  },
  "log In_mainstream": {
    en: `Log In`,
    no: `Logg Inn`,
  },
  "Login_casual": {
    en: `Login`,
    no: `Innlogging`,
  },
  "Login_mainstream": {
    en: `Login`,
    no: `Innlogging`,
  },
  "email_username Placeholder_casual": {
    en: `Email or username`,
    no: `E-post eller brukernavn`,
  },
  "email_username Placeholder_mainstream": {
    en: `Email or username`,
    no: `E-post eller brukernavn`,
  },
  //Fun Message Data
  "funMessageDataText_1_casual": {
    en: `Welcome! You have 6 more steps to complete 🙂`,
    no: `Velkommen! Du har 6 flere trinn å fullføre 🙂`,
  },
  "funMessageDataText_1_mainstream": {
    en: `Welcome! You got 6 steps to complete 🙂`,
    no: `Velkommen! Du har 6 trinn å fullføre 🙂`,
  },
  "funMessageDataText_2_casual": {
    en: `You're doing great!`,
    no: `Du gjør det flott!`,
  },
  "funMessageDataText_2_mainstream": {
    en: `Doing great!`,
    no: `Dette går kjempebra!`,
  },
  "funMessageDataText_3_casual": {
    en: `Halfway to complete!`,
    no: `Halvveis ferdig!`,
  },
  "funMessageDataText_3_mainstream": {
    en: `Halfway to complete!`,
    no: `Halvveis ferdig!`,
  },
  "funMessageDataText_4_casual": {
    en: `Just 3 more steps.`,
    no: `Bare 3 trinn igjen.`,
  },
  "funMessageDataText_4_mainstream": {
    en: `Only 3 steps left.`,
    no: `Kun 3 steg igjen.`,
  },
  "funMessageDataText_5_casual": {
    en: `2 steps to go...`,
    no: `2 trinn igjen...`,
  },
  "funMessageDataText_5_mainstream": {
    en: `2 more to go...`,
    no: `Bare 2 igjen...`,
  },
  "funMessageDataText_6_casual": {
    en: `There you go 🙂 Complete the registraiton!`,
    no: `Der ja 🙂 Fullfør registreringen!`,
  },
  "funMessageDataText_6_mainstream": {
    en: `Here you go 🙂 Complete your registration!`,
    no: `Se på deg 🙂, fullfør registreringen din!`,
  },
  //Question Data
  "questionData_1_casual": {
    en: `I am`,
    no: `Jeg er `,
  },
  "questionData_1_mainstream": {
    en: `I am`,
    no: `Jeg er `,
  },
  "questionData_2_casual": {
    en: `When is your birthday?`,
    no: `Når er bursdagen din?`,
  },
  "questionData_2_mainstream": {
    en: `What is your birthday?`,
    no: `Når fyller du året?`,
  },
  "questionData_3_casual": {
    en: `Where do you live?`,
    no: `Hvor bor du?`,
  },
  "questionData_3_mainstream": {
    en: `Where do you live?`,
    no: `Hvor bor du?`,
  },
  "questionData_4_casual": {
    en: `Choose a username`,
    no: `Velg et brukernavn`,
  },
  "questionData_4_mainstream": {
    en: `Choose a username`,
    no: `Velg et brukernavn`,
  },
  "questionData_5_casual": {
    en: `Set a password`,
    no: `Lag et passord`,
  },
  "questionData_5_mainstream": {
    en: `Set a password`,
    no: `Lag et passord`,
  },
  "questionData_6_casual": {
    en: `Add your email address`,
    no: `Oppgi din e-post adresse `,
  },
  "questionData_6_mainstream": {
    en: `Add your email address`,
    no: `Oppgi din e-post adresse `,
  },
  //Gender component
  "gender_casual": {
    en: `Gender`,
    no: `Kjønn`,
  },
  "gender_mainstream": {
    en: `Gender`,
    no: `Kjønn`,
  },
  "Male_casual": {
    en: `Male`,
    no: `Mann`,
  },
  "Male_mainstream": {
    en: `Male`,
    no: `Mann`,
  },
  "Female_casual": {
    en: `Female`,
    no: `Dame`,
  },
  "Female_mainstream": {
    en: `Female`,
    no: `Dame`,
  },
  //Age component
  "day_casual": {
    en: `Day`,
    no: `Dag`,
  },
  "day_mainstream": {
    en: `Day`,
    no: `Dag`,
  },
  "month_casual": {
    en: `Month`,
    no: `Måned`,
  },
  "month_mainstream": {
    en: `Month`,
    no: `Måned`,
  },
  "year_casual": {
    en: `Year`,
    no: `År`,
  },
  "year_mainstream": {
    en: `Year`,
    no: `År`,
  },
  //Location component
  "searchPlaceholder_casual": {
    en: `Type your city, for example London`,
    no: `Skriv din by, for eksempel Oslo`,
  },
  "searchPlaceholder_mainstream": {
    en: `Type your city, for example London`,
    no: `Skriv din by, for eksempel Oslo`,
  },
  "locationErrMessFE_casual": {
    en: `Location not found`,
    no: `Plassering ikke funnet`,
  },
  "locationErrMessFE_mainstream": {
    en: `Location not found`,
    no: `Plassering ikke funnet`,
  },
  //Username component
  "usernamePlaceholder_casual": {
    en: `Username`,
    no: `Brukernavn`,
  },
  "usernamePlaceholder_mainstream": {
    en: `Username`,
    no: `Brukernavn`,
  },
  "username text_casual": {
    en: `Username must be 3-12 characters should exclude any personal details.`,
    no: `Brukernavnet må være 3-12 tegn og bør utelukke personlige detaljer.`,
  },
  "username text_mainstream": {
    en: `Username must be 3-12 characters should exclude any personal details.`,
    no: `Brukernavnet må være 3-12 tegn og bør utelukke personlige detaljer.`,
  },
  "usernameErrMessFE_casual": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },
  "usernameErrMessFE_mainstream": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },
  //Password component
  "passwordPlaceholder_casual": {
    en: `Password`,
    no: `Passord`,
  },
  "passwordPlaceholder_mainstream": {
    en: `Password`,
    no: `Passord`,
  },
  "password text_casual": {
    en: `The password must be more than 6 characters long and include at least one number`,
    no: `Passordet må være lengre enn 6 tegn og inkludere minst ett tall`,
  },
  "password text_mainstream": {
    en: `The password must be more than 6 characters long and include at least one number`,
    no: `Passordet må være lengre enn 6 tegn og inkludere minst ett tall`,
  },
  "passErrMessFE_casual": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit.`,
    no: `Passordet ditt oppfyller ikke våre retningslinjer. Passordet må være 6-20 tegn og inneholde minst ett tall.`,
  },
  "passErrMessFE_mainstream": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit.`,
    no: `Passordet ditt oppfyller ikke våre retningslinjer. Passordet må være 6-20 tegn og inneholde minst ett tall.`,
  },
  //Email component
  "emailPlaceholder_casual": {
    en: `Email`,
    no: `E-post`,
  },
  "emailPlaceholder_mainstream": {
    en: `Email`,
    no: `E-post`,
  },
  "emailErrMessFE_casual": {
    en: `The email address is not valid, please try again`,
    no: `E-postadressen er ikke gyldig, vennligst prøv igjen`,
  },
  "emailErrMessFE_mainstream": {
    en: `The email address is not valid, please try again`,
    no: `E-postadressen er ikke gyldig, vennligst prøv igjen`,
  },
  //messageWithImgData
  "messageWithImgData_1_casual": {
    en: `These members are currently online 😉`,
    no: `Disse medlemmene er online akkurat nå 😉`,
  },
  "messageWithImgData_1_mainstream": {
    en: `These members are online now 😉`,
    no: `Disse medlemmene er online nå 😉`,
  },
  "messageWithImgData_2_casual": {
    en: `Get access to my naughty content 😉`,
    no: `Få tilgang til mitt frekke innhold 😉`,
  },
  "messageWithImgData_2_mainstream": {
    en: `Get access to my explicit content 😉`,
    no: `Få tilgang til mitt eksplisitte innhold 😉`,
  },
  "messageWithImgData_3_casual": {
    en: `Don’t miss out my highlights. I have some spicy pictures here 🌶️`,
    no: `Gå ikke glipp av mine høydepunkter. Jeg har noen hete bilder her 🌶️`,
  },
  "messageWithImgData_3_mainstream": {
    en: `Don’t miss out my highlights. I have some spicy pictures here 🌶️`,
    no: `Gå ikke glipp av mine høydepunkter. Jeg har noen hete bilder her 🌶️`,
  },
  "messageWithImgData_4_casual": {
    en: `More pictures to see here...`,
    no: `Flere bilder å se her...`,
  },
  "messageWithImgData_4_mainstream": {
    en: `More pictures to see here...`,
    no: `Flere bilder å se her...`,
  },
  "messageWithImgData_5_casual": {
    en: `Explore my feed`,
    no: `Utforsk min feed`,
  },
  "messageWithImgData_5_mainstream": {
    en: `Explore my feed`,
    no: `Utforsk min feed`,
  },
  //extraEmailData
  "extraEmailData_1_casual": {
    en: `Encrypted Information`,
    no: `Kryptert Informasjon`,
  },
  "extraEmailData_1_mainstream": {
    en: `Encrypted Information`,
    no: `Kryptert Informasjon`,
  },
  "extraEmailData_2_casual": {
    en: `Intellectual Property`,
    no: `Immaterielle Rettigheter`,
  },
  "extraEmailData_2_mainstream": {
    en: `Intellectual Property`,
    no: `Immaterielle Rettigheter`,
  },
  "extraEmailData_3_casual": {
    en: `Cloud Security`,
    no: `Cloud Security`,
  },
  "extraEmailData_3_mainstream": {
    en: `Cloud Security`,
    no: `Cloud Security`,
  },
  "extraEmailData_4_casual": {
    en: `SSL Protected`,
    no: `SSL Beskyttet`,
  },
  "extraEmailData_4_mainstream": {
    en: `SSL Protected`,
    no: `SSL Beskyttet`,
  },
  //extraLocationData
  "extraLocationData_1_casual": {
    en: `Stories`,
    no: `Stories`,
  },
  "extraLocationData_1_mainstream": {
    en: `Stories`,
    no: `Stories`,
  },
  "extraLocationData_2_casual": {
    en: `Highlights`,
    no: `Høydepunkter`,
  },
  "extraLocationData_2_mainstream": {
    en: `Highlights`,
    no: `Høydepunkter`,
  },
  "extraLocationData_3_casual": {
    en: `Black and ...`,
    no: `Svart og ...`,
  },
  "extraLocationData_3_mainstream": {
    en: `Black and ...`,
    no: `Svart og ...`,
  },
  "extraLocationData_4_casual": {
    en: `Videos`,
    no: `Videoer`,
  },
  "extraLocationData_4_mainstream": {
    en: `Videos`,
    no: `Videoer`,
  },
  "extraLocationErrMessFE_casual": {
    en: `You should log in first to see these highlights.`,
    no: `Du må logge inn først for å se disse høydepunktene`,
  },
  "extraLocationErrMessFE_mainstream": {
    en: `You should log in first to see these highlights.`,
    no: `Du må logge inn først for å se disse høydepunktene`,
  },
  //ToS acceptance
  "read and accept_casual": {
    en: `I have read and accepted the`,
    no: `Jeg har lest og godtatt`,
  },
  "read and accept_mainstream": {
    en: `I have read and accepted the`,
    no: `Jeg har lest og godtatt`,
  },
  "Terms of Service_casual": {
    en: `Terms of Service`,
    no: `Tjenestevilkår`,
  },
  "Terms of Service_mainstream": {
    en: `Terms of Service`,
    no: `Tjenestevilkår`,
  },
  "and our_casual": {
    en: `and our`,
    no: `og vår`,
  },
  "and our_mainstream": {
    en: `and our`,
    no: `og vår`,
  },
  "Privacy Statement_casual": {
    en: `Privacy Statement`,
    no: `Personvernerklæring`,
  },
  "Privacy Statement_mainstream": {
    en: `Privacy Statement`,
    no: `Personvernerklæring`,
  },
  //onlineUser
  "onlineUser1_casual": {
    en: `ChefMia`,
    no: `ChefMia`,
  },
  "onlineUser1_mainstream": {
    en: `ChefMia`,
    no: `ChefMia`,
  },
  "onlineUser1_name_casual": {
    en: `Mia Le`,
    no: `Mia Le`,
  },
  "onlineUser1_name_mainstream": {
    en: `Mia Le`,
    no: `Mia Le`,
  },
  "onlineUser1_bio_casual": {
    en: `Passionate chef with a love for global culinary adventures. Eager to find a companion who enjoys exquisite meals and impromptu journeys.`,
    no: `Lidenskapelig kokk med kjærlighet for globale kulinariske eventyr. Ivrig etter å finne en ledsager som nyter utsøkte måltider og spontane reiser.`,
  },
  "onlineUser1_bio_mainstream": {
    en: `Creative soul and travel-loving chef. Love exploring the world through food and culture. Looking for someone who appreciates great dinners and spontaneous adventures.`,
    no: `Kreativ sjel og reiselysten kokk. Elsker å utforske verden gjennom mat og kultur. Ser etter en som setter pris på gode middager og spontane eventyr.`,
  },
  "onlineUser2_casual": {
    en: `YogaMaren`,
    no: `YogaMaren`,
  },
  "onlineUser2_mainstream": {
    en: `YogaMaren`,
    no: `YogaMaren`,
  },
  "onlineUser2_name_casual": {
    en: `Maren Skogly`,
    no: `Maren Skogly`,
  },
  "onlineUser2_name_mainstream": {
    en: `Maren Skogly`,
    no: `Maren Skogly`,
  },
  "onlineUser2_bio_casual": {
    en: `Yoga devotee with a penchant for nature. You'll find me embracing the tranquility of dawn or lost in the beauty of the woods, seeking a soulmate for meaningful talks and serene outdoor moments.`,
    no: `Yogatilhenger med en forkjærlighet for natur. Du finner meg omfavne morgenens ro eller tapt i skogens skjønnhet, søker en sjelevenn for meningsfulle samtaler og fredelige utendørs øyeblikk`,
  },
  "onlineUser2_bio_mainstream": {
    en: `Nature-loving yoga instructor. Find me meditating at sunrise or wandering in the forest. Seeking a partner for deep conversations and relaxed days in nature.`,
    no: `Naturglad yogainstruktør. Finn meg meditrende i soloppgangen eller vandrende i skogen. Søker en partner for dype samtaler og avslappede dager i naturen.`,
  },
  "onlineUser3_casual": {
    en: `HistFride`,
    no: `HistFride`,
  },
  "onlineUser3_mainstream": {
    en: `HistFride`,
    no: `HistFride`,
  },
  "onlineUser3_name_casual": {
    en: `Fride Haugland`,
    no: `Fride Haugland`,
  },
  "onlineUser3_name_mainstream": {
    en: `Fride Haugland`,
    no: `Fride Haugland`,
  },
  "onlineUser3_bio_casual": {
    en: `Avid reader and aficionado of historical tales. My evenings are filled with engrossing reads and documentaries about the past, looking for someone who cherishes storytelling and historical intrigue`,
    no: `Ivrig leser og aficionado av historiske fortellinger. Mine kvelder er fylt med fengslende lesing og dokumentarer om fortiden, ser etter noen som setter pris på historiefortelling og historisk intrige.`,
  },
  "onlineUser3_bio_mainstream": {
    en: `Bookworm and history enthusiast. I spend my evenings with a good book or a historical documentary. Desiring someone with a taste for stories and past mysteries.`,
    no: `Bokorm og historieentusiast. Jeg tilbringer mine kvelder med en god bok eller en historisk dokumentar. Ønsker en med sans for fortellinger og fortidens mysterier.`,
  },
  "onlineUser4_casual": {
    en: `IngiFilm`,
    no: `IngiFilm`,
  },
  "onlineUser4_mainstream": {
    en: `IngiFilm`,
    no: `IngiFilm`,
  },
  "onlineUser4_name_casual": {
    en: `Ingrid Rønning`,
    no: `Ingrid Rønning`,
  },
  "onlineUser4_name_mainstream": {
    en: `Ingrid Rønning`,
    no: `Ingrid Rønning`,
  },
  "onlineUser4_bio_casual": {
    en: `Film enthusiast and budding theater performer. Ideal dates include cozy movie nights or exploring the magic of local theater. Seeking someone with an appreciation for cinematic arts and performance.`,
    no: `Filmelsker og spirrende teaterutøver. Ideelle dater inkluderer koselige filmkvelder eller utforske magien i lokalt teater. Søker noen med en forkjærlighet for kinokunst og fremførelse.`,
  },
  "onlineUser4_bio_mainstream": {
    en: `Movie lover and amateur actress. My ideal date is a movie night or exploring local theatres. Looking for someone who values art and drama.`,
    no: `Filmelsker og amatørskuespiller. Min ideelle date er en filmkveld eller å utforske lokale teatre. Ser etter en som verdsetter kunst og drama.`,
  },
  "onlineUser5_casual": {
    en: `DiveMari`,
    no: `DiveMari`,
  },
  "onlineUser5_mainstream": {
    en: `DiveMari`,
    no: `DiveMari`,
  },
  "onlineUser5_name_casual": {
    en: `Mari Gulbrandsen`,
    no: `Mari Gulbrandsen`,
  },
  "onlineUser5_name_mainstream": {
    en: `Mari Gulbrandsen`,
    no: `Mari Gulbrandsen`,
  },
  "onlineUser5_bio_casual": {
    en: `Diving expert and ocean scientist. My realm is the depths of the sea. Searching for a partner to share in oceanic explorations and maritime discoveries.`,
    no: `Dykkeekspert og havforsker. Mitt rike er havdypene. Søker en partner for å dele i havutforskning og maritime oppdagelser.`,
  },
  "onlineUser5_bio_mainstream": {
    en: `Certified diver and marine biologist. My world is underwater. Looking for someone to share diving trips and the wonders of the sea.`,
    no: `Sertifisert dykker og marinbiolog. Min verden er under vann. Leter etter noen å dele dykketurer og havets underverker med`,
  },
  "onlineUser6_casual": {
    en: `VikiTunes`,
    no: `VikiTunes`,
  },
  "onlineUser6_mainstream": {
    en: `VikiTunes`,
    no: `VikiTunes`,
  },
  "onlineUser6_name_casual": {
    en: `Viktoria Dalseth`,
    no: `Viktoria Dalseth`,
  },
  "onlineUser6_name_mainstream": {
    en: `Viktoria Dalseth`,
    no: `Viktoria Dalseth`,
  },
  "onlineUser6_bio_casual": {
    en: `Artistically inclined musician, weaving melodies and lyrics. My days are filled with music creation, hoping to meet a partner who resonates with the rhythm of creativity and song.`,
    no: `Kunstnerisk tilbøyelig musiker, veving av melodier og tekster. Mine dager er fylt med musikkskaping, håper å møte en partner som resonnerer med kreativitetens og sangens rytme.`,
  },
  "onlineUser6_bio_mainstream": {
    en: `Musician and songwriter. I spend my days with a guitar and notebooks. Dreaming of a partner who appreciates music and creative souls.`,
    no: `Musiker og låtskriver. Jeg tilbringer dagene med gitar og notebøker. Drømmer om en partner som setter pris på musikk og kreative sjeler.`,
  },
  "onlineUser7_casual": {
    en: `FitMartine`,
    no: `FitMartine`,
  },
  "onlineUser7_mainstream": {
    en: `FitMartine`,
    no: `FitMartine`,
  },
  "onlineUser7_name_casual": {
    en: `Martine Løchen`,
    no: `Martine Løchen`,
  },
  "onlineUser7_name_mainstream": {
    en: `Martine Løchen`,
    no: `Martine Løchen`,
  },
  "onlineUser7_bio_casual": {
    en: `Health-conscious fitness guide. I thrive on staying active and living healthily. Looking for a partner who shares a commitment to wellness and a vigorous lifestyle.`,
    no: `Helsebevisst fitnessguide. Jeg trives med å være aktiv og leve sunt. Ser etter en partner som deler et engasjement for velvære og en energisk livsstil.`,
  },
  "onlineUser7_bio_mainstream": {
    en: `Fitness enthusiast and personal trainer. I love an active lifestyle and healthy habits. Desiring a partner who also values health and fitness.`,
    no: `Fitnessentusiast og personlig trener. Jeg elsker en aktiv livsstil og sunne vaner. Ønsker en partner som også verdsetter helse og fitness.`,
  },
  "onlineUser8_casual": {
    en: `OliWine`,
    no: `OliWine`,
  },
  "onlineUser8_mainstream": {
    en: `OliWine`,
    no: `OliWine`,
  },
  "onlineUser8_name_casual": {
    en: `Oliwia Trondsen`,
    no: `Oliwia Trondsen`,
  },
  "onlineUser8_name_mainstream": {
    en: `Oliwia Trondsen`,
    no: `Oliwia Trondsen`,
  },
  "onlineUser8_bio_casual": {
    en: `Connoisseur of fine wines and expert in viticulture. My adventures include exploring the world through the lens of wine tasting. In search of a companion who savors fine wine and engaging dialogues.`,
    no: `Kjennere av fine viner og ekspert i vinavl. Mine eventyr inkluderer å utforske verden gjennom vinopplevelser. På utkikk etter en følgesvenn som setter pris på god vin og engasjerende samtaler`,
  },
  "onlineUser8_bio_mainstream": {
    en: `Wine lover and sommelier. I explore the world through different wine tastings. Looking for someone who can enjoy a good glass of wine and interesting conversations.`,
    no: `Vinelsker og sommelier. Jeg utforsker verden gjennom ulike vinsmakinger. Ser etter en som kan nyte et godt glass vin og interessante samtaler.`,
  },
  "onlineUser9_casual": {
    en: `TheaSnap`,
    no: `TheaSnap`,
  },
  "onlineUser9_mainstream": {
    en: `TheaSnap`,
    no: `TheaSnap`,
  },
  "onlineUser9_name_casual": {
    en: `Thea Soltveit`,
    no: `Thea Soltveit`,
  },
  "onlineUser9_name_mainstream": {
    en: `Thea Soltveit`,
    no: `Thea Soltveit`,
  },
  "onlineUser9_bio_casual": {
    en: `Explorer with a camera, capturing life's moments. Seeking a kindred spirit who is passionate about photographic adventures and discovering the world through a lens.`,
    no: `Eventyrer med et kamera, fanger livets øyeblikk. Søker en likesinnet som er lidenskapelig om fotografiske eventyr og å oppdage verden gjennom et objektiv.`,
  },
  "onlineUser9_bio_mainstream": {
    en: `Adventurous photographer. I capture the world through my camera. Seeking someone who shares my passion for adventure and photography.`,
    no: `Eventyrlysten fotograf. Jeg fanger verden gjennom mitt kamera. Leter etter en som deler min lidenskap for eventyr og fotografi.`,
  },
  "onlineUser10_casual": {
    en: `LizTech`,
    no: `LizTech`,
  },
  "onlineUser10_mainstream": {
    en: `LizTech`,
    no: `LizTech`,
  },
  "onlineUser10_name_casual": {
    en: `Elizabeth Antonsen`,
    no: `Elizabeth Antonsen`,
  },
  "onlineUser10_name_mainstream": {
    en: `Elizabeth Antonsen`,
    no: `Elizabeth Antonsen`,
  },
  "onlineUser10_bio_casual": {
    en: `Enthusiast in technology and video game creation. Fascinated by the digital realm, I seek someone who shares my enthusiasm for tech innovations and virtual worlds`,
    no: `Teknologientusiast og videospillutvikler. Fascinert av den digitale verdenen, søker jeg noen som deler min begeistring for teknologiske innovasjoner og virtuelle verdener.`,
  },
  "onlineUser10_bio_mainstream": {
    en: `Tech-nerd and game developer. I love everything about technology and gaming. Looking for someone who shares my interest in digital worlds`,
    no: `Teknologi-nerd og spillutvikler. Jeg elsker alt som har med teknologi og spill å gjøre. Søker noen som deler min interesse for digitale verdener.`,
  },
  "onlineUser11_casual": {
    en: `EriTravel`,
    no: `EriTravel`,
  },
  "onlineUser11_mainstream": {
    en: `EriTravel`,
    no: `EriTravel`,
  },
  "onlineUser11_name_casual": {
    en: `Erika Midttun`,
    no: `Erika Midttun`,
  },
  "onlineUser11_name_mainstream": {
    en: `Erika Midttun`,
    no: `Erika Midttun`,
  },
  "onlineUser11_bio_casual": {
    en: `Polyglot and globetrotter. Fluent in multiple languages, always planning my next cultural expedition. Looking for a partner who is excited by international travel and cultural exploration.`,
    no: `Polyglott og globetrotter. Flytende i flere språk, alltid planlegger min neste kulturelle ekspedisjon. Ser etter en partner som er begeistret for internasjonal reise og kulturell utforskning.`,
  },
  "onlineUser11_bio_mainstream": {
    en: `Language enthusiast and world traveler. I speak four languages and am planning my next destination. Seeking someone who loves new cultures and exciting travels.`,
    no: `Språkentusiast og verdensreisende. Jeg snakker fire språk og planlegger min neste destinasjon. På jakt etter en som elsker nye kulturer og spennende reiser.`,
  },
  "onlineUser12_casual": {
    en: `AlinaGreen`,
    no: `AlinaGreen`,
  },
  "onlineUser12_mainstream": {
    en: `AlinaGreen`,
    no: `AlinaGreen`,
  },
  "onlineUser12_name_casual": {
    en: `Alina Høgset`,
    no: `Alina Høgset`,
  },
  "onlineUser12_name_mainstream": {
    en: `Alina Høgset`,
    no: `Alina Høgset`,
  },
  "onlineUser12_bio_casual": {
    en: `Eco-conscious gardener, nurturing nature's beauty. My life revolves around growing a sustainable haven and environmental stewardship. Hoping to find a partner who is equally dedicated to ecological well-being and the splendor of the natural world.`,
    no: `Miljøbevisst gartner, pleier naturens skjønnhet. Mitt liv kretser rundt å dyrke en bærekraftig havn og miljøstyring. Håper å finne en partner som er like dedikert til økologisk velvære og naturens prakt.`,
  },
  "onlineUser12_bio_mainstream": {
    en: `Gardener and environmentalist. My days are spent cultivating my own garden and caring for the environment. Desiring a partner equally passionate about sustainability and nature's beauty.`,
    no: `Gartner og naturverner. Mine dager tilbringes med å dyrke min egen hage og ta vare på miljøet. Ønsker en partner som er like engasjert i bærekraft og naturens skjønnhet.`,
  },
  // translation of months
  "January_casual": {
    en: `January`,
    no: `Januar`,
  },
  "January_mainstream": {
    en: `January`,
    no: `Januar`,
  },
  "February_casual": {
    en: `February`,
    no: `Februar`,
  },
  "February_mainstream": {
    en: `February`,
    no: `Februar`,
  },
  "March_casual": {
    en: `March`,
    no: `Mars`,
  },
  "March_mainstream": {
    en: `March`,
    no: `Mars`,
  },
  "April_casual": {
    en: `April`,
    no: `April`,
  },
  "April_mainstream": {
    en: `April`,
    no: `April`,
  },
  "May_casual": {
    en: `May`,
    no: `Mai`,
  },
  "May_mainstream": {
    en: `May`,
    no: `Mai`,
  },
  "June_casual": {
    en: `June`,
    no: `Juni`,
  },
  "June_mainstream": {
    en: `June`,
    no: `Juni`,
  },
  "July_casual": {
    en: `July`,
    no: `Juli`,
  },
  "July_mainstream": {
    en: `July`,
    no: `Juli`,
  },
  "August_casual": {
    en: `August`,
    no: `August`,
  },
  "August_mainstream": {
    en: `August`,
    no: `August`,
  },
  "September_casual": {
    en: `September`,
    no: `September`,
  },
  "September_mainstream": {
    en: `September`,
    no: `September`,
  },
  "October_casual": {
    en: `October`,
    no: `Oktober`,
  },
  "October_mainstream": {
    en: `October`,
    no: `Oktober`,
  },
  "November_casual": {
    en: `November`,
    no: `November`,
  },
  "November_mainstream": {
    en: `November`,
    no: `November`,
  },
  "December_casual": {
    en: `December`,
    no: `Desember`,
  },
  "December_mainstream": {
    en: `December`,
    no: `Desember`,
  },
  //error message translation content
  "Field already exists._casual": {
    en: `An account containing identical details already exists in our system`,
    no: `En konto med identiske opplysninger finnes allerede i vårt system`,
  },
  "Field already exists._mainstream": {
    en: `An account containing identical details already exists in our system`,
    no: `En konto med identiske opplysninger finnes allerede i vårt system`,
  },
    "Provide valid email._casual": {
    en: `Please enter a valid email address to proceed`,
    no: `Vennligst oppgi en gyldig e-postadresse for å fortsette`,
  },
  "Provide valid email._mainstream": {
    en: `Please enter a valid email address to proceed`,
    no: `Vennligst oppgi en gyldig e-postadresse for å fortsette`,
  },
  "Google recaptcha error._casual": {
    en: `There has been an error with the Google reCAPTCHA verification process`,
    no: `Det har oppstått en feil med Google reCAPTCHA-verifiseringsprosessen`,
  },
  "Google recaptcha error._mainstream": {
    en: `There has been an error with the Google reCAPTCHA verification process`,
    no: `Det har oppstått en feil med Google reCAPTCHA-verifiseringsprosessen`,
  },
  "Provide google recaptcha token._casual": {
    en: `Please provide the token generated by the Google reCAPTCHA for verification`,
    no: `Vennligst oppgi tokenet generert av Google reCAPTCHA for verifikasjon`,
  },
  "Provide google recaptcha token._mainstream": {
    en: `Please provide the token generated by the Google reCAPTCHA for verification`,
    no: `Vennligst oppgi tokenet generert av Google reCAPTCHA for verifikasjon`,
  },
  "Google recaptcha user suspicious behaviour._casual": {
    en: `Google reCAPTCHA has detected suspicious activity associated with your behavior`,
    no: `Google reCAPTCHA har oppdaget mistenkelig aktivitet knyttet til din oppførsel`,
  },
  "Google recaptcha user suspicious behaviour._mainstream": {
    en: `Google reCAPTCHA has detected suspicious activity associated with your behavior`,
    no: `Google reCAPTCHA har oppdaget mistenkelig aktivitet knyttet til din oppførsel`,
  },
  "Wrong user id provided._casual": {
    en: `The user ID you have provided is incorrect or does not exist`,
    no: `Bruker-ID-en du har oppgitt er feil eller eksisterer ikke`,
  },
  "Wrong user id provided._mainstream": {
    en: `The user ID you have provided is incorrect or does not exist`,
    no: `Bruker-ID-en du har oppgitt er feil eller eksisterer ikke`,
  },
  "User age must be above 18 years._casual": {
    en: `Users must be at least 18 years old to create an account`,
    no: `Brukere må være minst 18 år gamle for å opprette en konto`,
  },
  "User age must be above 18 years._mainstream": {
    en: `Users must be at least 18 years old to create an account`,
    no: `Brukere må være minst 18 år gamle for å opprette en konto`,
  },
  //Duplicate from backend error response
  "Username requirements don't match._casual": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },
  "Username requirements don't match._mainstream": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },
  "Password requirements don't match._casual": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit.`,
    no: `Ditt passord overholder ikke våre krav. Det skal være på 6-20 tegn og må inkludere minst ett tall.`,
  },
  "Password requirements don't match._mainstream": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit.`,
    no: `Ditt passord overholder ikke våre krav. Det skal være på 6-20 tegn og må inkludere minst ett tall.`,
  },
  // Backend error Login messages
  "Provide email or username._casual": {
    en: `Provide email or username.`,
    no: `Oppgi e-post eller brukernavn.`,
  },
  "Provide email or username._mainstream": {
    en: `Provide email or username.`,
    no: `Oppgi e-post eller brukernavn.`,
  },
  "Provide password._casual": {
    en: `Provide password.`,
    no: `Oppgi passord.`,
  },
  "Provide password._mainstream": {
    en: `Provide password.`,
    no: `Oppgi passord.`,
  },
  "Wrong email or username._casual": {
    en: `Wrong email or username.`,
    no: `Feil e-post eller brukernavn.`,
  },
  "Wrong email or username._mainstream": {
    en: `Wrong email or username.`,
    no: `Feil e-post eller brukernavn.`,
  },
  "Wrong password._casual": {
    en: `Wrong password.`,
    no: `Feil passord.`,
  },
  "Wrong password._mainstream": {
    en: `Wrong password.`,
    no: `Feil passord.`,
  },
};
