import React from "react";
import { StepperComp } from "../../common/StepperComp";
import { FunMessageComp } from "../../common/FunMessageComp";
import { styled, Box, Divider } from "@mui/material";
import { QuestionComp } from "../../common/QuestionComp";
import { useDispatch, useSelector } from "react-redux";
import { ButtonBack } from "../../common/ButtonBack";
import { MessageWithImgComp } from "../../common/MessageWithImgComp";
import { Gender } from "../../formSteps/Gender";
import { Age } from "../../formSteps/Age";
import { Location } from "../../formSteps/Location";
import { Username } from "../../formSteps/Username";
import { Password } from "../../formSteps/Password";
import { Email } from "../../formSteps/Email";
import { ExtraGender } from "../../extraSteps/ExtraGender";
import { ExtraAge } from "../../extraSteps/ExtraAge";
import { ExtraLocation } from "../../extraSteps/ExtraLocation";
import { ExtraUsername } from "../../extraSteps/ExtraUsername";
import { ExtraPassword } from "../../extraSteps/ExtraPassword";
import { ExtraEmail } from "../../extraSteps/ExtraEmail";
import { back } from "../../../features/activeStepSlice";
import { addErrorMessage } from "../../../features/errorMessageSlice";
import { setModalState } from "../../../features/modalStateSlice";

const FormContentBox = styled(Box)({
  background: "#fff",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
});

const HeaderBox = styled(Box)(({ theme }) => ({
  paddingBottom: "16px",
  boxShadow: "0px 4px 4px 0px #00000005",
  position: "sticky",
  top: 0,
  zIndex: 100,
  background: "#fff",
  width: "100%",

  [theme.breakpoints.up("sm")]: {
    boxShadow: "none",
  },
}));

export const SignupDialog = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const dispatch = useDispatch();

  const handleBack = () => {
    if (activeStep === 0) {
      dispatch(setModalState({ isOpen: true, type: "suggestion" }));
    } else {
      dispatch(back());
    }
    dispatch(addErrorMessage(""));
  };

  const formStepsCompArr = [
    <Gender />,
    <Age />,
    <Location />,
    <Username />,
    <Password />,
    <Email />,
  ];

  const extraStepsContentArr = [
    <ExtraGender />,
    <ExtraAge />,
    <ExtraLocation isShowErrMessage={true} />,
    <ExtraUsername />,
    <ExtraPassword />,
    <ExtraEmail />,
  ];

  return (
    <FormContentBox>
      <Box sx={{ padding: { xs: "0 16px", sm: "0" } }}>
        <HeaderBox>
          <StepperComp />
          <FunMessageComp />
        </HeaderBox>
        <Box>
          <QuestionComp />
          {formStepsCompArr[activeStep]}
          <ButtonBack onClick={handleBack} />
          {activeStep < 5 && (
            <>
              <Divider
                variant="fullWidth"
                sx={{ backgroundColor: "primary.lightGrey" }}
              />
              <MessageWithImgComp />
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          padding:
            activeStep === 0
              ? "0"
              : activeStep === 2
              ? "0"
              : { xs: "0 16px", sm: "0" },
        }}
      >
        {extraStepsContentArr[activeStep]}
      </Box>
    </FormContentBox>
  );
};
