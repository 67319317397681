import React from "react";
import { onlineUsersImages } from "../../dataUi/homeUserCartData";
import { Box, styled, useTheme, useMediaQuery } from "@mui/material";
import { TooltipUserInfo } from "./TooltipUserInfo";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "../../features/modalStateSlice";
import { UserModalComp } from "./UserModalComp";
import { ImageWithStatus } from "./ImageWithStatus";
import { InfiniteLooper } from "./InfiniteLooper ";

const SlideBox = styled(Box)(({ theme }) => ({
  height: "90px",
  width: "90px",
  display: "flex",
  alignItems: "center",
  padding: "5px",

  [theme.breakpoints.up("400")]: {
    height: "96px",
    width: "96px",
    padding: "6px",
  },
}));

export const CarouselComp = () => {
  const modalState = useSelector((state) => state.modalState);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  const handleClick = (item) => {
    dispatch(
      setModalState({
        isOpen: true,
        type: modalState.type,
        previousState: modalState.type,
        isOpenSmallModal: true,
      })
    );
    localStorage.setItem("current_online_user_id", item);
  };

  const result = (start, end) =>
    onlineUsersImages?.slice(start, end)?.map((item) => (
      <SlideBox key={item}>
        {!isSmallScreen ? (
          <TooltipUserInfo item={item} />
        ) : (
          <ImageWithStatus
            src={require(`./../../images/onlineUser${item}_${dynamicType}.png`)}
            sx={{
              imgCompStyles: { width: "100%", cursor: "pointer" },
              onlineStatusStyles: { right: "10px", top: "70px" },
            }}
            onClick={() => handleClick(item)}
          />
        )}
      </SlideBox>
    ));

  return (
    <Box sx={{ display: "grid", placeItems: "center" }}>
      <InfiniteLooper speed="10" direction="right" children={result(0, 4)} />
      <InfiniteLooper speed="10" direction="left" children={result(4, 8)} />
      <InfiniteLooper speed="10" direction="right" children={result(8, 12)} />
      {isSmallScreen && <UserModalComp />}{" "}
    </Box>
  );
};
