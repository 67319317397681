import React from "react";
import { Box, useMediaQuery, styled, useTheme } from "@mui/material";
import { HomeUserName } from "./home/homeComp/HomeUserName";
import { HomeUserBtnFolow } from "./home/homeComp/HomeUserBtnFolow";
import { ExtraLocation } from "./extraSteps/ExtraLocation";
import { Divider } from "@mui/material";
import { HomeUserPostCart } from "./home/homeComp/HomeUserPostCart";
import { homeUserCartData } from "../dataUi/homeUserCartData";
import { HomeUserHeader } from "./home/homeComp/HomeUserHeader";
import { ModalComp } from "./common/ModalComp";
import { useDispatch } from "react-redux";
import { setModalState } from "../features/modalStateSlice";
import { HomeLeftMenu } from "./home/homeComp/HomeLeftMenu";
import { HomeBottomMenu } from "./home/homeComp/HomeBottomMenu";
import { HomeUserDescription } from "./home/homeComp/HomeUserDescription";

const HomeWrapperBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  maxWidth: "734px",
  margin: "0 auto",
});

const HomeBox = styled(Box)(({ theme }) => ({
  width: "502px",
  overflowY: "auto",
  borderLeft: "1.4px solid #E5E8EB",
  borderRight: "1.4px solid #E5E8EB",
  cursor: "pointer",
  margin: "0 auto 70px",

  [theme.breakpoints.up("sm")]: {
    margin: "0 auto",
  },
}));

const HomeUserBtnFolowWrapperBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "0 16px 24px",

  [theme.breakpoints.up("sm")]: {
    flexDirection: "column-reverse",
    margin: "0 12px 32px",
  },
}));

export const Home = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  const handleClick = () => {
    dispatch(setModalState({ isOpen: true, type: "suggestion" }));
  };

  return (
    <Box>
      {isSmallScreen && <HomeBottomMenu />}
      <HomeWrapperBox>
        {!isSmallScreen && <HomeLeftMenu />}
        <HomeBox onClick={handleClick}>
          <HomeUserHeader
            isHeader={true}
            sx={{
              wrapperBox: {
                height: { xs: "196px", sm: "248px" },
              },
            }}
          />
          <HomeUserName
            first={true}
            staticText={true}
            sx={{
              wrapperBox: {
                margin: { xs: "42px 16px 0", sm: "50px 12px 0" },
              },
            }}
          />
          <HomeUserBtnFolowWrapperBox>
            <HomeUserBtnFolow
              mainButtonText="Subscribe"
              sx={{
                boxWrapper: { m: { xs: "0 0 24px", sm: "24px 0 0" } },
              }}
            />
            <HomeUserDescription text={`home_content_3_${dynamicType}`} />
          </HomeUserBtnFolowWrapperBox>
          <ExtraLocation isShowErrMessage={false} />
          <Divider
            sx={{ margin: isSmallScreen ? "21px 16px 24px" : "32px 0" }}
          />
          {homeUserCartData?.map((item) => (
            <HomeUserPostCart
              key={item.id}
              text={item.text}
              src={require(`./home/images/homeUserCartData_${item.id}_${dynamicType}.png`)}
            />
          ))}
          <ModalComp />
        </HomeBox>
      </HomeWrapperBox>
    </Box>
  );
};
