import React from "react";
import { Button, styled } from "@mui/material";
import { Translate } from "react-translated";

const ButtonNextStles = styled(Button)(({ theme }) => ({
  height: "40px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  marginTop: "16px",
  marginBottom: "12px",
  background: "#F76448",
  color: "#FFFFFF",
  lineHeight: "24px",
  fontSize: "16px",
  "&:hover": {
    backgroundColor: "rgba(247, 100, 72, 0.9)",
  },

  [theme.breakpoints.up("sm")]: {
    height: "44px",
  },
}));

export const ButtonNext = ({
  text,
  disabled = false,
  onClick = null,
  style = {},
}) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <ButtonNextStles
      onClick={onClick}
      disabled={disabled}
      style={style}
      variant="Subtitle4"
    >
      <Translate text={`${text}_${dynamicType}`} />
    </ButtonNextStles>
  );
};
