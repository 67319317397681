import React from "react";
import { Box, styled } from "@mui/material";
import {
  generateOptions,
  generateYearOptions,
  getMonthsName,
} from "../../helpers/DateUtils";
import { FormControlComp } from "../common/FormControlComp";
import { ButtonNext } from "../common/ButtonNext";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { next } from "../../features/activeStepSlice";

const AgeDataBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export const Age = () => {
  const userData = useSelector((state) => state.userData?.value);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(addField({ [name]: value }));
  };

  const handleNext = () => {
    if (userData.day && userData.month && userData.year) {
      dispatch(next());
    }
  };

  return (
    <>
      <AgeDataBox>
        <FormControlComp
          value={userData.day || ""}
          handleChange={handleChange}
          label="day"
          name="day"
          getOption={generateOptions(31)}
          labelId="day-label"
          id="day-select"
          sx={{ width: { xs: "24%", sm: "30%" } }}
        />
        <FormControlComp
          value={userData.month || ""}
          handleChange={handleChange}
          label="month"
          name="month"
          getOption={getMonthsName()}
          labelId="month-label"
          id="month-select"
          sx={{ width: { xs: "38%", sm: "30%" } }}
        />
        <FormControlComp
          value={userData.year || ""}
          handleChange={handleChange}
          label="year"
          name="year"
          getOption={generateYearOptions()}
          labelId="year-label"
          id="year-select"
          sx={{ width: { xs: "30%", sm: "30%" } }}
        />
      </AgeDataBox>

      <ButtonNext
        onClick={handleNext}
        text="Next"
        disabled={!userData.day || !userData.month || !userData.year}
      />
    </>
  );
};
