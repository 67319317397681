import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Translate } from "react-translated";

const commentsData = [
  {
    id: 0,
    userName: "alenthegeek",
    comment: "OMG!! So so gorgeous.. Girl you are on fire",
  },
  {
    id: 1,
    userName: "nelson33",
    comment: "🔥🔥🔥",
  },
  {
    id: 2,
    userName: "mariosup",
    comment: "OMG!! So so gorgeous.. Girl you are on fire 🔥🔥🔥",
  },
  {
    id: 3,
    userName: "mariosup",
    comment: "Girl you are on fire. Girl you are on fire 🔥🔥🔥",
  },
  {
    id: 4,
    userName: "mariosup",
    comment: "OMG!! 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥",
  },
];

export const HomeUserComments = () => {
  const [showComments, setShowComments] = useState(false);
  const displayedComments = showComments
    ? commentsData
    : commentsData.slice(0, 3);
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Box sx={{ margin: { xs: "0 16px 32px", sm: "0 12px 40px" } }}>
      {displayedComments?.map((item) => (
        <Typography
          key={item.id}
          variant="Body5"
          sx={{ display: "block", marginBottom: { xs: "4px", sm: "8px" } }}
        >
          <b>{item.userName}</b> {item.comment}
        </Typography>
      ))}
      <Typography
        variant="Body2"
        sx={{ mt: 1 / 2, cursor: "pointer", display: "block" }}
        onClick={() => setShowComments(!showComments)}
      >
        <Translate text={`home_content_4_${dynamicType}`} />
      </Typography>
    </Box>
  );
};
