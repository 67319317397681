import React from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { questionData } from "../../dataUi/formContentData";
import { Translate } from "react-translated";

export const QuestionComp = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Typography
      variant="Subtitle3"
      align="center"
      sx={{ margin: { xs: "24px auto 20px", sm: "16px auto 20px" } }}
      component="h2"
    >
      <Translate text={`${questionData[activeStep]}_${dynamicType}`} />
    </Typography>
  );
};
