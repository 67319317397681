import React from "react";
import { Box } from "@mui/material";
import { leftMenuData } from "../../../dataUi/homeUserCartData";
import { HomeUserIconText } from "./HomeUserIconText";
import { useDispatch } from "react-redux";
import { setModalState } from "../../../features/modalStateSlice";

export const HomeBottomMenu = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setModalState({ isOpen: true, type: "suggestion" }));
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        height: "84px",
        boxSizing: "border-box",
        position: "fixed",
        bottom: "-2px",
        width: "100%",
        background: "white",
        zIndex: 10,
        boxShadow: "0px -4px 4px 0px #00000005",
        borderTop: "1px solid #E5E8EB",
      }}
    >
      <Box
        sx={{
          maxWidth: "480px",
          padding: "16px 0 12px 16px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {leftMenuData?.map((item) => (
          <HomeUserIconText
            active={
              item.id === 2 && {
                color: "#3265AA",
              }
            }
            key={item.id}
            Icon={item.Icon}
            sx={{
              icon: {
                color: "primary.grey",
                maxWidth: "30px",
                maxHeight: "30px",
                fontSize: "30px",
                mr: 2,
              },
              text: {
                color: "primary.grey",
              },
              backgroundStyle: {
                mb: 3,
                mr: 0,
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};
