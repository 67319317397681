import React from "react";
import { Box, styled } from "@mui/material";
import { HomeUserIconText } from "./HomeUserIconText";
import { homeUserHeaderIconsData } from "../../../dataUi/homeUserCartData";
import StarIcon from "@mui/icons-material/Star";
import ReplySharpIcon from "@mui/icons-material/ReplySharp";
import { IconWithStyle } from "../../common/IconWithStyle";
import { ImageWithStatus } from "../../common/ImageWithStatus";
const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

const HomeUserHeaderBox = styled(Box)(({ srcbg, sx }) => ({
  margin: "0 0 12px",
  backgroundImage: `url(${
    srcbg || require(`./../images/home_${dynamicType}.png`)
  })`,
  backgroundRepeat: "no-repeat",
  minHeight: "184px",
  backgroundSize: "cover",
  position: "relative",
  ...sx,
}));

const ImgWrapperrBox = styled(Box)(({ theme, sx }) => ({
  height: "120px",
  position: "absolute",
  width: "-webkit-fill-available",
  top: "110px",
  padding: "0 16px",
  display: "flex",
  justifyContent: "space-between",
  ...sx,

  [theme.breakpoints.up("sm")]: {
    top: "162px",
  },
}));

const IconsWrapperBox = styled(Box)({
  display: "flex",
  alignItems: "flex-end",
  paddingBottom: "3px",
});

export const HomeUserHeader = ({
  isHeader,
  sx = { wrapperBox: "", imgWrapperBox: "" },
  srcBg = "",
  srcImg = "",
}) => {
  return (
    <HomeUserHeaderBox srcbg={srcBg} sx={sx?.wrapperBox}>
      {isHeader && (
        <Box
          sx={{
            display: "inline-flex",
            margin: { xs: 2, sm: 1.5 },
          }}
        >
          {homeUserHeaderIconsData?.map((item) => (
            <HomeUserIconText
              key={item.id}
              srcImg={item.srcImg}
              text={item.text}
              Icon={item.Icon}
              sx={{
                icon: {
                  color: "primary.main",
                },
                backgroundStyle: {
                  background: "#ffffffb8",
                  padding: { xs: "4px 8px", sm: "14px" },
                  borderRadius: "6px",
                  height: { sm: "47px" },
                },
                text: {
                  color: "primary.main",
                  marginLeft: { xs: "6px", sm: "10px" },
                },
              }}
            />
          ))}
        </Box>
      )}
      <ImgWrapperrBox sx={sx?.imgWrapperBox}>
        <ImageWithStatus
          src={
            srcImg ||
            require(`./../images/homeUserHeaderOnline_${dynamicType}.png`)
          }
          sx={{
            imgCompStyles: {
              width: "120px",
              height: "120px",
            },
            onlineStatusStyles: {
              top: "98px",
              right: "18px",
            },
          }}
        />

        {isHeader && (
          <IconsWrapperBox>
            <IconWithStyle icon={StarIcon} />
            <IconWithStyle
              icon={ReplySharpIcon}
              sx={{ marginLeft: "8px", transform: "scaleX(-1)" }}
            />
          </IconsWrapperBox>
        )}
      </ImgWrapperrBox>
    </HomeUserHeaderBox>
  );
};
