import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  palette: {
    primary: {
      main: "#212B36",
      red: "#F52626",
      grey: "#B2B3B5",
      lightGrey: "#E5E8EB",
      darkGrey: "#637381",
      green: "#2EC876",
      black: "#657084",
      orange: "#F76448",
    },
  },

  typography: {
    Subtitle2: {
      fontSize: "18px",
      color: "#212B36",
      fontWeight: "600",
      lineHeight: "26px",
    },
    Subtitle3: {
      fontSize: "20px",
      color: "#212B36",
      fontWeight: "500",
      lineHeight: "32px",
    },
    Subtitle4: {
      fontSize: "16px",
      color: "#fff",
      fontWeight: "600",
      lineHeight: "24px",
    },
    Body1: {
      fontSize: "18px",
      color: "#212B36",
      fontWeight: "400",
      lineHeight: "26px",
    },
    Body2: {
      fontSize: "16px",
      color: "#B2B3B5",
      fontWeight: "400",
      lineHeight: "24px",
    },
    Body3: {
      fontSize: "14px",
      color: "#B2B3B5",
      fontWeight: "400",
      lineHeight: "24px",
    },
    Body4: {
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    Body5: {
      fontSize: "14px",
      color: "#637381",
      fontWeight: "400",
      lineHeight: "22px",
    },
  },
});

export default theme;
