import React from "react";
import { Typography } from "@mui/material";
import { Translate } from "react-translated";

export const HomeUserDescription = ({ text, sx = {} }) => {
  const truncatedText = text.length > 200 ? `${text.slice(0, 200)}...` : text;

  return (
    <Typography
      variant="Body2"
      sx={{ color: "#212B36", display: " inline-block", ...sx }}
    >
      <Translate text={truncatedText} />
    </Typography>
  );
};
