import React from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { funMessageData } from "../../dataUi/formContentData";
import { Translate } from "react-translated";

export const FunMessageComp = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Typography
      variant="Body3"
      align="center"
      sx={{ display: "block", fontSize: { sm: "16px" } }}
    >
      <Translate text={`${funMessageData[activeStep]}_${dynamicType}`} />
    </Typography>
  );
};
