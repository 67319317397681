import React from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  styled,
  useTheme,
} from "@mui/material";
import homeNameChecked from "./../images/homeNameChecked.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Translate } from "react-translated";

const UserNameLastSeeTextBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: "0 0 8px",

  [theme.breakpoints.up("sm")]: {
    margin: 0,
  },
}));

const TypographyDot = styled(Typography)({
  width: "4px",
  height: "4px",
  background: "#B2B3B5",
  margin: "8px",
  borderRadius: "50%",
});

const LocationWithTextBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "16px",
  marginTop: "8px",

  [theme.breakpoints.up("sm")]: {
    marginBottom: "24px",
    marginTop: "16px",
  },
}));

const LocationOnOutlinedIconStyle = styled(LocationOnOutlinedIcon)(
  ({ theme }) => ({
    color: `${theme.palette.primary.main}`,
    height: "20px",
    marginRight: "4px",
    fontSize: "20px",
  })
);

export const HomeUserName = ({
  first = false,
  staticText = false,
  sx = { wrapperBox: "", nameStyle: "" },
  idx = null,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;
  const currentOnlineUserId =
    idx || localStorage.getItem("current_online_user_id");

  return (
    <Box sx={{ margin: "0 16px", ...sx?.wrapperBox }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: first ? "6px" : 0,
        }}
      >
        <Typography
          variant="Subtitle4"
          sx={{
            color: "primary.main",
            mr: "10px",
            ...sx?.nameStyle,
          }}
        >
          {staticText ? (
            "Karina  Malenko"
          ) : (
            <Translate
              text={`onlineUser${currentOnlineUserId}_name_${dynamicType}`}
            />
          )}
        </Typography>
        {currentOnlineUserId % 3 !== 0 && (
          <img src={homeNameChecked} alt="homeNameChecked" />
        )}
      </Box>
      <UserNameLastSeeTextBox>
        <Typography
          component="span"
          variant={isSmallScreen ? "Body3" : "Body2"}
        >
          {staticText ? (
            "@malenko234"
          ) : (
            <Translate
              text={`onlineUser${currentOnlineUserId}_${dynamicType}`}
            />
          )}
        </Typography>
        {first && (
          <>
            <TypographyDot />
            <Typography
              component="span"
              variant={isSmallScreen ? "Body3" : "Body2"}
            >
              <Translate text={`home_content_1_${dynamicType}`} />
            </Typography>
          </>
        )}
      </UserNameLastSeeTextBox>
      {first && (
        <LocationWithTextBox>
          <LocationOnOutlinedIconStyle />
          <Typography variant="Body2" sx={{ color: "primary.main" }}>
            <Translate text={`home_content_2_${dynamicType}`} />
          </Typography>
        </LocationWithTextBox>
      )}
    </Box>
  );
};
