import React from "react";
import { Box } from "@mui/material";
import { ButtonNext } from "../../common/ButtonNext";
import { ButtonImg } from "../../common/ButtonImg";
import instagram from "./../images/instagram.png";
import xBlog from "./../images/xBlog.png";
import tiktok from "./../images/tiktok.png";
import { setModalState } from "../../../features/modalStateSlice";
import { useDispatch } from "react-redux";

export const HomeUserBtnFolow = ({
  mainButtonText = "",
  modalType = "",
  sx = {},
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      setModalState({ isOpen: true, type: modalType, isOpenSmallModal: false })
    );
    localStorage.removeItem("current_online_user_id");
  };

  return (
    <Box
      sx={{
        margin: "0 16px 24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...sx?.boxWrapper,
      }}
    >
      <ButtonNext
        text={mainButtonText}
        disabled={false}
        onClick={handleClick}
        style={{ margin: 0, fontWeight: "500", ...sx?.nextBtn }}
      />
      <ButtonImg
        imgSrc={instagram}
        onClick={handleClick}
        sx={{ ...sx?.buttonStyle }}
      />
      <ButtonImg
        imgSrc={xBlog}
        onClick={handleClick}
        sx={{ ...sx?.buttonStyle }}
      />
      <ButtonImg
        imgSrc={tiktok}
        onClick={handleClick}
        sx={{ ...sx?.buttonStyle }}
      />
    </Box>
  );
};
