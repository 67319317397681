import React from "react";
import { TextField, styled, Box } from "@mui/material";
import { Translator } from "react-translated";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const InputBoxWrapper = styled(Box)(({ theme }) => ({
  border: "1px solid #F76448",
  width: "100%",
  borderRadius: "8px",
  height: "40px",
  padding: "0 12px",
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",

  [theme.breakpoints.up("sm")]: {
    height: "44px",
  },
}));

const InputBox = styled(TextField)(() => ({
  width: "100%",
}));

export const Input = ({
  name = "",
  value,
  handleChange,
  placeholder,
  typeInput,
  handleValidation,
  isValid = false,
  extraComp = "",
  sx = {},
  autoFocus = false,
}) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <InputBoxWrapper variant="Body1" sx={{ ...sx }}>
      <Translator>
        {({ translate }) => (
          <InputBox
            id={`${name}-field-id`}
            name={name}
            autoComplete="off"
            placeholder={translate({
               text: `${placeholder}_${dynamicType}`,
            })}
            className="inputBox"
            value={value}
            onChange={handleChange}
            onBlur={handleValidation}
            type={typeInput}
            autoFocus={autoFocus}
          />
        )}
      </Translator>
      {extraComp}
      {!isValid && <CheckCircleIcon sx={{ color: "primary.green" }} />}
    </InputBoxWrapper>
  );
};
