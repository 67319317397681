import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Link,
} from "@mui/material";
import { Input } from "../common/Input";
import { ButtonNext } from "../common/ButtonNext";
import { ErrorMessage } from "../common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { setActiveStep } from "../../features/activeStepSlice";
import { Translate } from "react-translated";
import { useDebounce } from "../../hooks/useDebounce";
import axios from "axios";
import { setModalState } from "../../features/modalStateSlice";
import { RecaptchaToken } from "./../common/RecaptchaToken";

export const Email = () => {
  const userData = useSelector((state) => state.userData?.value);
  const [email, setEmail] = useState(userData.email || "");
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const userId = sessionStorage.getItem("user_id");
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const message = "emailErrMessFE";
  const useDebounceValue = useDebounce(email, 1000, regex, message);
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  const handleChange = (e) => {
    setEmail(e.target.value);
    dispatch(addErrorMessage(""));
  };

  useEffect(() => {
    if (useDebounceValue || !errorMessage) {
      dispatch(addField({ email: useDebounceValue }));
    } else {
      dispatch(addField({ email: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDebounceValue]);

  const fetchCompleteData = async () => {
    try {
      setIsLoading(true);

      const response = await apiUrl.post(
        `/registration/${userId}`,
        {
          email: useDebounceValue,
          DOB: `${userData.year}-${userData.month}-${userData.day}`,
          location: userData.location,
          gender: userData.gender,
          password: userData.password,
          looking_for: userData.looking_for,
          recaptcha_token: userData.recaptcha_token,
          ef_transaction_id: userData.ef_transaction_id,
        },
        {
          params: {
            site_key: "no01",
          },
        }
      );

      const getAccessToken = await apiUrl.post(
        `/sessions`,
        {
          password: userData.password,
          email: useDebounceValue,
          recaptcha_token: userData.recaptcha_token,
        },
        {
          params: {
            site_key: "no01",
          },
        }
      );
      localStorage.setItem(
        "access_token",
        getAccessToken?.data?.Data?.access_token
      );
      const accessToken = localStorage.getItem("access_token");

      if (response?.data.Status === "ok" && userId && accessToken) {
        window.location.href = `https://intimflorts.com/discovery/?token=${accessToken}`;
        dispatch(addErrorMessage(""));
      }
    } catch (error) {
      if (error?.response?.data.Status === "fail") {
        errorCodeChangeActiveState(error?.response?.data?.Error?.code);
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const errorCodeChangeActiveState = (code) => {
    // age error
    if (code === 103) {
      dispatch(setActiveStep(1));
    }
    // password error
    else if (code === 102 || code === 105 || code === 118) {
      dispatch(setActiveStep(4));
    }
    // email error
    else if (code === 100 || code === 106) {
      dispatch(setActiveStep(5));
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
  };

  const handleNext = () => {
    if (useDebounceValue && isChecked) {
      try {
        dispatch(addErrorMessage(""));
        fetchCompleteData();
      } catch (error) {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    }
  };

  return (
    <Box className="userBox">
      <Input
        name="emailMain"
        value={email}
        handleChange={handleChange}
        placeholder="emailPlaceholder"
        typeInput="email"
        isValid={
          !useDebounceValue || isLoading || !email || !userData.recaptcha_token
        }
        autoFocus={true}
      />
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

      <FormGroup sx={{ marginTop: { xs: "10px", sm: "12px" } }}>
        <FormControlLabel
          sx={{
            marginRight: "0",
            display: "flex",
            alignItems: "flex-start",
          }}
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              sx={{
                padding: "6px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&.Mui-checked": {
                  color: "primary.green",
                },
              }}
            />
          }
          label={
            <Typography
              variant="Body3"
              color="primary.main"
              sx={{ marginLeft: "10px" }}
            >
              <Translate text={`read and accept_${dynamicType}`} />{" "}
              <Link
                sx={{ color: "primary.green", textDecoration: "none" }}
                onClick={() =>
                  dispatch(
                    setModalState({
                      isOpen: true,
                      type: "terms",
                      isOpenSmallModal: false,
                    })
                  )
                }
              >
                <Translate text={`Terms of Service_${dynamicType}`} />
              </Link>{" "}
              <Translate text={`and our_${dynamicType}`} />{" "}
              <Link
                sx={{ color: "primary.green", textDecoration: "none" }}
                onClick={() =>
                  dispatch(
                    setModalState({
                      isOpen: true,
                      type: "privacy",
                      isOpenSmallModal: false,
                    })
                  )
                }
              >
                <Translate text={`Privacy Statement_${dynamicType}`} />
              </Link>
            </Typography>
          }
        />
      </FormGroup>

      <ButtonNext
        onClick={handleNext}
        text="Complete"
        style={{ cursor: !isLoading ? "pointer" : "no-drop" }}
        disabled={
          !useDebounceValue ||
          !isChecked ||
          isLoading ||
          !!errorMessage ||
          !email ||
          !userData.recaptcha_token
        }
      />
      <RecaptchaToken />
    </Box>
  );
};
