export const buttons = {
  //Form buttons
  "Back_casual": {
    en: `Back`,
    no: `Tillbake`,
  },
  "Back_mainstream": {
    en: `Back`,
    no: `Tillbake`,
  },
  "Next_casual": {
    en: `Next`,
    no: `Neste`,
  },
  "Next_mainstream": {
    en: `Next`,
    no: `Neste`,
  },
  "Complete_casual": {
    en: `Complete`,
    no: `Fullfør`,
  },
  "Complete_mainstream": {
    en: `Complete`,
    no: `Fullfør`,
  },
  "Yes_casual": {
    en: `Yes`,
    no: `Ja`,
  },
  "Yes_mainstream": {
    en: `Yes`,
    no: `Ja`,
  },
  "No_casual": {
    en: `No`,
    no: `Nei`,
  },
  "No_mainstream": {
    en: `No`,
    no: `Nei`,
  },
  "Message Me_casual": {
    en: `Message Me`,
    no: `Send melding til meg`,
  },
  "Message Me_mainstream": {
    en: `Message Me`,
    no: `Send melding til meg`,
  },
  "Subscribe_casual": {
    en: `Subscribe`,
    no: `Abonnere`,
  },
  "Subscribe_mainstream": {
    en: `Subscribe`,
    no: `Abonnere`,
  },
};
