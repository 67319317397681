import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Translate } from "react-translated";
import { messageWithImgData } from "../../dataUi/formContentData";
import { ImageWithStatus } from "./ImageWithStatus";

const MessageWithImgBox = styled(Box)({
  margin: "32px auto 20px",
  width: "100%",
  display: "flex",
});

const MessageTextBox = styled(Box)({
  borderRadius: "0px 12px 12px 12px",
  boxShadow: "0px 2px 6px 0px #00000014",
  marginLeft: "16px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: "12px",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
});

export const MessageWithImgComp = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <MessageWithImgBox>
      <ImageWithStatus
        src={require(`./../../components/home/images/homeUserCart_${dynamicType}.png`)}
        sx={{
          imgCompStyles: { width: "48px", height: "48px" },
          onlineStatusStyles: {
            right: "0",
            top: "35px",
            width: "8px",
            height: "8px",
          },
        }}
      />

      <MessageTextBox>
        <Typography
          variant={isSmScreen ? "Body3" : "Body2"}
          color="primary.main"
        >
          <Translate
            text={`${messageWithImgData[activeStep]}_${dynamicType}`}
          />
        </Typography>
      </MessageTextBox>
    </MessageWithImgBox>
  );
};
