import React, { useState } from "react";
import { ExtraLocationCart } from "../common/ExtraLocationCart";
import { extraLocationData } from "../../dataUi/formContentData";
import { Box, styled } from "@mui/material";
import { ErrorMessage } from "../common/ErrorMessage";

const ExtraLocationBox = styled(Box)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
}));

const ExtraLocationCartWrapperBox = styled(Box)({
  display: "-webkit-box",
  overflowX: "auto",
  overflowY: "hidden",
  WebkitOverflowScrolling: "touch",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const ErrorMessageWrapperBox = styled(Box)(({ theme }) => ({
  padding: "6px 16px 16px",
  background: "#FFDCDC",
  borderRadius: "8px",
  margin: "32px 16px 0",

  [theme.breakpoints.up("sm")]: {
    margin: "32px 0 0 0",
  },
}));

export const ExtraLocation = ({ sx = {}, isShowErrMessage = false }) => {
  const [showErrMes, setShowErrMess] = useState(false);
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <ExtraLocationBox>
      <ExtraLocationCartWrapperBox>
        {extraLocationData?.map((item) => (
          <ExtraLocationCart
            key={item.id}
            imgSrc={require(`./../../images/extraLocation_${item.id}_${dynamicType}.png`)}
            btName={item.btName}
            onClick={() => setShowErrMess(true)}
          />
        ))}
      </ExtraLocationCartWrapperBox>
      {isShowErrMessage && showErrMes && (
        <ErrorMessageWrapperBox>
          <ErrorMessage errorMessage="extraLocationErrMessFE" />
        </ErrorMessageWrapperBox>
      )}
    </ExtraLocationBox>
  );
};
