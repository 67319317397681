import React from "react";
import { Box } from "@mui/material";
import { ImageWithStatus } from "./ImageWithStatus";
import { HomeUserName } from "../home/homeComp/HomeUserName";
import { HomeUserDescription } from "../home/homeComp/HomeUserDescription";

export const UserInfoTooltip = ({ item }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <>
      <Box sx={{ display: "flex", mb: "20px", alignItems: "center" }}>
        <ImageWithStatus
          src={require(`./../../images/onlineUser${item}_${dynamicType}.png`)}
          sx={{
            imgCompStyles: { width: "90px", height: "90px" },
            onlineStatusStyles: { right: "10px", top: "70px" },
          }}
        />
        <HomeUserName
          sx={{
            wrapperBox: {
              width: "160px",
              margin: "20px 0 0 12px",
            },
            nameStyle: {
              fontSize: "15px",
            },
          }}
          idx={item}
        />
      </Box>
      <HomeUserDescription text={`onlineUser${item}_bio_${dynamicType}`} />
    </>
  );
};
