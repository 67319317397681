import React from "react";
import { Box } from "@mui/material";
import { leftMenuData } from "../../../dataUi/homeUserCartData";
import { HomeUserIconText } from "./HomeUserIconText";
import { useDispatch } from "react-redux";
import { setModalState } from "../../../features/modalStateSlice";

export const HomeLeftMenu = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setModalState({ isOpen: true, type: "suggestion" }));
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        width: "152px",
        minWidth: "152px",
        height: "300px",
        mr: "20px",
        position: "sticky",
        top: 0,
        cursor: "pointer",
        padding: "16px 8px",
        boxSizing: "border-box",
      }}
    >
      {leftMenuData?.map((item) => (
        <HomeUserIconText
          active={
            item.id === 2 && {
              color: "#3265AA",
            }
          }
          key={item.id}
          text={item.text}
          Icon={item.Icon}
          sx={{
            icon: {
              color: "primary.grey",
              maxWidth: "30px",
              maxHeight: "30px",
              fontSize: "30px",
              mr: 2,
            },
            text: {
              color: "primary.grey",
            },
            backgroundStyle: {
              mb: 3,
              mr: 0,
            },
          }}
        />
      ))}
    </Box>
  );
};
