import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { Input } from "../common/Input";
import { ButtonNext } from "../common/ButtonNext";
import { ErrorMessage } from "../common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { next } from "../../features/activeStepSlice";
import { Translate } from "react-translated";
import { useDebounce } from "../../hooks/useDebounce";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const Password = () => {
  const userData = useSelector((state) => state.userData?.value);
  const [password, setPassword] = useState(userData.password || "");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const regex = /^(?=.*\d).{6,20}$/;
  const message = "passErrMessFE";
  const useDebounceValue = useDebounce(password, 500, regex, message);
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length < 21) {
      setPassword(value);
      dispatch(addErrorMessage(""));
    }
  };

  useEffect(() => {
    if (useDebounceValue || !errorMessage) {
      dispatch(addField({ password: useDebounceValue }));
    } else {
      dispatch(addField({ password: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDebounceValue]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleNext = () => {
    if (useDebounceValue && password) {
      try {
        dispatch(next());
        dispatch(addErrorMessage(""));
      } catch (error) {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    }
  };

  return (
    <Box className="userBox">
      <Input
        name="passwordMain"
        value={password}
        handleChange={handleChange}
        placeholder="passwordPlaceholder"
        typeInput={showPassword ? "text" : "password"}
        isValid={!useDebounceValue || !!errorMessage}
        extraComp={
          <IconButton onClick={togglePasswordVisibility}>
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        }
        autoFocus={true}
      />

      {!errorMessage ? (
        <Typography
          variant="Body4"
          display="block"
          sx={{
            marginTop: "10px",
            color: "primary.grey",
            fontSize: { sm: "14px" },
            lineHeight: { xs: "20px" },
          }}
        >
          <Translate text={`password text_${dynamicType}`} />
        </Typography>
      ) : (
        <ErrorMessage errorMessage={errorMessage} />
      )}

      <ButtonNext
        onClick={handleNext}
        text="Next"
        style={{ cursor: useDebounceValue ? "pointer" : "no-drop" }}
        disabled={!useDebounceValue || !password}
      />
    </Box>
  );
};
