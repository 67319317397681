import React from "react";
import { Box, styled } from "@mui/material";

const IconsWrapperBox = styled(Box)(({ theme, sx }) => ({
  background: " #ffffffb8",
  padding: "4px 8px",
  borderRadius: "6px",
  boxShadow: "0px 2px 4px 0px #00000014",
  color: `${theme.palette.primary.orange}`,
  width: "40px",
  height: "40px",
  boxSizing: "border-box",
  ...sx,

  [theme.breakpoints.up("sm")]: {
    width: "44px",
    height: "44px",
  },
}));

export const IconWithStyle = ({ icon: Icon, sx }) => {
  return <IconsWrapperBox component={Icon} sx={sx} />;
};
