import React from "react";
import { ExtraEmailCart } from "../common/ExtraEmailCart";
import { Box, Divider, styled, useMediaQuery, useTheme } from "@mui/material";
import { extraEmailData } from "../../dataUi/formContentData";

const ExtraEmailBox = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: "8px",
  boxSizing: "border-box",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",

  [theme.breakpoints.up("sm")]: {
    marginTop: "0",
    padding: "0 20px",
  },
}));

export const ExtraEmail = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {!isSmallScreen && <Divider sx={{ mb: 4 }} />}
      <ExtraEmailBox>
        {extraEmailData?.map((item) => (
          <ExtraEmailCart
            key={item.id}
            iconName={item.iconName}
            text={item.text}
          />
        ))}
      </ExtraEmailBox>
    </>
  );
};
