import React from "react";
import {
  Box,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Translate } from "react-translated";

const HomeUserIconTextBox = styled(Box)(({ theme, sx }) => ({
  display: "flex",
  marginRight: "8px",
  alignItems: "center",
  height: "32px",
  boxSizing: "border-box",
  ...sx,

  [theme.breakpoints.up("sm")]: {
    marginRight: "16px",
    pending: "14px",
  },
}));

const HomeUserIconTypography = styled(Typography)(({ sx, style }) => ({
  ...style,
  ...sx,
}));

export const HomeUserIconText = ({
  srcImg = "",
  text = "",
  sx = { backgroundStyle: "", icon: "", text: "" },
  Icon = "",
  active = "",
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <HomeUserIconTextBox sx={{ ...sx?.backgroundStyle }}>
      {srcImg && <img src={srcImg} alt="srcImg" />}
      {Icon && <Icon sx={{ ...sx?.icon }} style={{ ...active }} />}
      {text && (
        <HomeUserIconTypography
          variant={!isSmallScreen ? "Body2" : "Body3"}
          sx={{ ...sx?.text }}
          style={{ ...active }}
        >
          {text.toString().includes("home_content") ? (
            <Translate text={`${text}_${dynamicType}`} />
          ) : (
            text
          )}
        </HomeUserIconTypography>
      )}
    </HomeUserIconTextBox>
  );
};
