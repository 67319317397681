import React, { useState, useEffect } from "react";
import { Box, InputBase, MenuItem } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import { ButtonNext } from "../common/ButtonNext";
import axios from "axios";
import { Translator } from "react-translated";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { next } from "../../features/activeStepSlice";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { ErrorMessage } from "../common/ErrorMessage";

const LocationCompBox = styled(Box)(() => ({
  position: "relative",
}));

const SearchBox = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  width: "100%",
  border: "1px solid #F76448",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 16px",
  boxSizing: "border-box",
  height: "40px",

  [theme.breakpoints.up("sm")]: {
    height: "44px",
  },
}));

const LocationBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  background: "white",
  width: "100%",
  zIndex: "10",
  maxHeight: "160px",
  overflowY: "auto",
  borderRadius: "10px",
  boxShadow: "0  0 4px #cacaca",

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#cacaca",
    border: "4px solid transparent",
    borderRadius: "8px",
    backgroundClip: "padding-box",
  },

  "&::-webkit-scrollbar": {
    width: "16px",
  },

  [theme.breakpoints.up("sm")]: {
    maxHeight: "250px",
  },
}));

export const Location = () => {
  const userData = useSelector((state) => state.userData?.value);
  const apiUrl = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const [searchVal, setSearchVal] = useState("");
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(userData.location || "");
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchVal(value);
    setLocation("");
    dispatch(addErrorMessage(""));
  };

  const handleLocationSelect = (item) => {
    setLocation(item);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiUrl.get("/registration/locations", {
          params: {
            search: searchVal,
            skip: 0,
            limit: 10,
            site_key: "no01",
          },
        });
        if (response?.data?.Data?.length > 0) {
          setLocations(response?.data?.Data);
        } else {
          setLocations([]);
          dispatch(addErrorMessage("locationErrMessFE"));
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (searchVal !== "") {
      fetchData();
    } else {
      setLocations([]);
      dispatch(addErrorMessage(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  useEffect(() => {
    if (location) {
      dispatch(addField({ location: location }));
    } else {
      dispatch(addField({ location: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleNext = () => {
    if (location) {
      dispatch(next());
    }
  };

  return (
    <LocationCompBox>
      <SearchBox>
        <Translator>
          {({ translate }) => (
            <InputBase
              placeholder={translate({
                text: `searchPlaceholder_${dynamicType}`,
              })}
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearchChange}
              sx={{ width: "100%", fontSize: "16px" }}
              value={location || searchVal}
              autoFocus
            />
          )}
        </Translator>
        <SearchIcon sx={{ color: "primary.grey", fontSize: "22px" }} />
      </SearchBox>

      {!location && searchVal && (
        <LocationBox>
          {locations.map((item) => (
            <MenuItem
              id="location-menu-item"
              sx={{
                fontSize: "16px",
                lineHeight: "20px",
                minHeight: "auto",
              }}
              key={item}
              onClick={() => handleLocationSelect(item)}
            >
              {item}
            </MenuItem>
          ))}
        </LocationBox>
      )}

      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

      <ButtonNext onClick={handleNext} text="Next" disabled={!location} />
    </LocationCompBox>
  );
};
