import React from "react";
import { styled, Button } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ImageWithStatus } from "./ImageWithStatus";
import { UserInfoTooltip } from "./UserInfoTooltip";

const MainTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
    width: "35px",
    height: "20px",
    bottom: "-10px !important",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: `${theme.palette.primary.main}`,
    boxShadow: "0px 0px 20px 0px #0000005C",
    width: "266px",
    minHeight: "254px",
    padding: "12px",
  },
}));

export const TooltipUserInfo = ({ item }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <MainTooltip placement="top" title={<UserInfoTooltip item={item} />}>
      <Button sx={{ p: 0, borderRadius: "50%" }}>
        <ImageWithStatus
          src={require(`./../../images/onlineUser${item}_${dynamicType}.png`)}
          sx={{
            imgCompStyles: { width: "100%", cursor: "pointer" },
            onlineStatusStyles: { right: "10px", top: "70px" },
          }}
        />
      </Button>
    </MainTooltip>
  );
};
