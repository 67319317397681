import React from "react";
import { Box, styled, keyframes } from "@mui/material";
import { ImageComp } from "./ImageComp";

const ImageOnlineStatus = styled(Box)(() => ({
  position: "relative",
}));

const OnlineStatusBox = styled(Box)(({ sx }) => ({
  width: "12px",
  height: "12px",
  background: "#74df69",
  borderRadius: "50%",
  position: "absolute",
  boxShadow: "0 0 5px #fff",
  animation: `${pulse} 1s infinite`,
  border: "2px solid #fff",
  ...sx,
}));

const pulse = keyframes`
  0% {transform: scale(1)}
  50% {transform: scale(1.05); opacity: 0.9}
  100% {transform: scale(1)}
`;

export const ImageWithStatus = ({ src, sx, onClick = null }) => {
  return (
    <ImageOnlineStatus>
      <ImageComp src={src} sx={{ ...sx?.imgCompStyles }} onClick={onClick} />
      <OnlineStatusBox sx={{ ...sx?.onlineStatusStyles }} />
    </ImageOnlineStatus>
  );
};
