import React, { useState } from "react";
import axios from "axios";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { Input } from "../../common/Input";
import { ButtonNext } from "../../common/ButtonNext";
import { useDispatch, useSelector } from "react-redux";
import { addErrorMessage } from "../../../features/errorMessageSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ErrorMessage } from "../../common/ErrorMessage";
import { ExtraGender } from "../../extraSteps/ExtraGender";
import { MessageWithImgComp } from "../../common/MessageWithImgComp";
import { Translate } from "react-translated";

export const LoginDialog = () => {
  const [formData, setFormData] = useState({
    email_username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const apiUrl = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const dispatch = useDispatch();
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;
  const isEmail = /\S+@\S+\.\S+/;
  let requestData;

  if (isEmail.test(formData.email_username)) {
    requestData = {
      password: formData.password,
      email: formData.email_username,
      recaptcha_token: null,
    };
  } else {
    requestData = {
      password: formData.password,
      username: formData.email_username,
      recaptcha_token: null,
    };
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    dispatch(addErrorMessage(""));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const fetchCompleteData = async () => {
    try {
      const getAccessToken = await apiUrl.post(`/sessions`, requestData, {
        params: {
          site_key: "no01",
        },
      });
      localStorage.setItem(
        "access_token",
        getAccessToken?.data?.Data?.access_token
      );
      const accessToken = localStorage.getItem("access_token");
      if (accessToken && !errorMessage) {
        window.location.href = `https://intimflorts.com/discovery/?token=${accessToken}`;
        dispatch(addErrorMessage(""));
      }
    } catch (error) {
      if (error?.response?.data.Status === "fail") {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    }
  };

  const handleNext = () => {
    if (formData.email_username && formData.password) {
      try {
        dispatch(addErrorMessage(""));
        fetchCompleteData();
      } catch (error) {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    }
  };

  return (
    <Box className="userBox">
      <Box sx={{ padding: { xs: "0 16px 16px", sm: "0 0 16px" } }}>
        <Typography
          variant="Subtitle3"
          sx={{ marginBottom: "20px", textAlign: "center", display: "block" }}
        >
          <Translate text={`Login_${dynamicType}`} />
        </Typography>
        <Input
          name="email_username"
          value={formData.email_username}
          handleChange={handleChange}
          placeholder="email_username Placeholder"
          typeInput="text"
          isValid={true}
          sx={{ mb: 2 }}
          autoFocus={true}
        />

        <Input
          name="password"
          value={formData.password}
          handleChange={handleChange}
          placeholder="passwordPlaceholder"
          typeInput={showPassword ? "text" : "password"}
          isValid={true}
          autoFocus={false}
          extraComp={
            <IconButton onClick={togglePasswordVisibility}>
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          }
        />

        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

        <ButtonNext
          onClick={handleNext}
          text="Login"
          style={{
            fontWeight: "500",
            cursor:
              formData.email_username && formData.password
                ? "pointer"
                : "no-drop",
          }}
          disabled={
            !!errorMessage || !formData.email_username || !formData.password
          }
        />
        <Divider
          variant="fullWidth"
          sx={{ backgroundColor: "primary.lightGrey", mt: 2 }}
        />
        <MessageWithImgComp />
      </Box>
      <ExtraGender />
    </Box>
  );
};
