import React from "react";
import { Typography } from "@mui/material";
import { Translate } from "react-translated";

export const DialogSubtitle = ({ text }) => {
  return (
    <Typography
      variant="Body1"
      sx={{
        mt: 3,
        display: "block",
        fontWeight: "600",
        color: "primary.darkGrey",
      }}
    >
      <Translate text={text} />
    </Typography>
  );
};
