import React from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  styled,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { setModalState } from "../../features/modalStateSlice";
import { HomeUserHeader } from "../home/homeComp/HomeUserHeader";
import { HomeUserName } from "../home/homeComp/HomeUserName";
import { HomeUserBtnFolow } from "../home/homeComp/HomeUserBtnFolow";
import { HomeUserDescription } from "../home/homeComp/HomeUserDescription";

const DialogCloseBtn = styled(Box)({
  display: "flex",
  alignItems: "center",
  fontWeight: "700",
  padding: "10px",
});

export const UserModalComp = () => {
  const modalState = useSelector((state) => state.modalState);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const currentOnlineUserId = localStorage.getItem("current_online_user_id");
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  const handleClose = () => {
    dispatch(
      setModalState({
        isOpen: true,
        type: modalState.previousState,
        isOpenSmallModal: false,
      })
    );
    localStorage.removeItem("current_online_user_id");
  };

  return (
    currentOnlineUserId && (
      <Dialog
        onClick={(e) => {
          e.stopPropagation();
        }}
        open={modalState.isOpenSmallModal && isSmallScreen}
        onClose={handleClose}
        fullWidth
        fullScreen={isSmallScreen}
        maxWidth="xl"
        PaperProps={{
          sx: {
            borderRadius: {
              xs: "8px",
              sm: "16px",
            },
            my: 2,
            height: { xs: "auto" },
            width: { xs: "92%" },
          },
        }}
      >
        <DialogCloseBtn sx={{ justifyContent: "flex-end" }}>
          <IconButton onClick={handleClose} sx={{ color: "primary.grey" }}>
            <CloseIcon sx={{ fontSize: "24px" }} />
          </IconButton>
        </DialogCloseBtn>

        <DialogContent
          sx={{
            userSelect: "none",
            lineHeight: "1.5",
            fontSize: "1rem",
            fontWeight: "400",
            color: "rgb(99, 115, 129)",
            padding: "0 16px 24px",
          }}
        >
          <HomeUserHeader
            isHeader={false}
            srcBg={
              currentOnlineUserId
                ? require(`./../../images/onlineUserBgImg${currentOnlineUserId}_${dynamicType}.png`)
                : ""
            }
            srcImg={
              currentOnlineUserId
                ? require(`./../../images/onlineUser${currentOnlineUserId}_${dynamicType}.png`)
                : ""
            }
            sx={{
              wrapperBox: { minHeight: "168px" },
              imgWrapperBox: {
                bottom: 0,
                top: "108px",
                padding: "0 12px",
              },
            }}
          />
          <HomeUserName
            sx={{
              wrapperBox: {
                minWidth: "172px",
                margin: "0 0 12px 140px",
              },
            }}
          />
          <HomeUserDescription
            text={`onlineUser${currentOnlineUserId}_bio_${dynamicType}`}
            sx={{ marginBottom: "24px" }}
          />
          <HomeUserBtnFolow
            mainButtonText="Message Me"
            modalType={modalState.previousState}
            sx={{
              boxWrapper: { m: 0 },
              buttonStyle: { marginLeft: "8px" },
              nextBtn: { fontSize: "15px", padding: "6px" },
            }}
          />
        </DialogContent>
      </Dialog>
    )
  );
};
