import React from "react";
import { Box, styled } from "@mui/material";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import gender_active from "./../../images/gender_active.png";
import gender_past from "./../../images/gender_past.png";
import { useSelector } from "react-redux";

const StepperCompBox = styled(Box)({
  width: "100%",
  margin: "0 0 16px",
});

const RangeBox = styled(Box)({
  width: "100%",
  height: "4px",
  background: "#E5E8EB",
  borderRadius: "2px",
});

export const StepperComp = () => {
  const activeStep = useSelector((state) => state.activeStep.value);

  const widthStep = 3 + (94 / 5) * activeStep;

  return (
    <StepperCompBox>
      <Box
        sx={{
          display: "flex",
          marginBottom: "12px",
          justifyContent: "space-between",
          color: "#E5E8EB",
        }}
      >
        {activeStep === 0 ? (
          <img src={gender_active} alt="gender_active" />
        ) : (
          <img src={gender_past} alt="gender_past" />
        )}

        <CakeOutlinedIcon
          sx={{
            color:
              activeStep === 1
                ? "#F76448"
                : activeStep > 1
                ? "#FFDC22"
                : "#E5E8EB",
          }}
        />

        <FmdGoodOutlinedIcon
          sx={{
            color:
              activeStep === 2
                ? "#F76448"
                : activeStep > 2
                ? "#FFDC22"
                : "#E5E8EB",
          }}
        />

        <PersonOutlineOutlinedIcon
          sx={{
            color:
              activeStep === 3
                ? "#F76448"
                : activeStep > 3
                ? "#FFDC22"
                : "#E5E8EB",
          }}
        />

        <LockOutlinedIcon
          sx={{
            color:
              activeStep === 4
                ? "#F76448"
                : activeStep > 4
                ? "#FFDC22"
                : "#E5E8EB",
          }}
        />

        <EmailOutlinedIcon
          sx={{
            color:
              activeStep === 5
                ? "#F76448"
                : activeStep > 5
                ? "#FFDC22"
                : "#E5E8EB",
          }}
        />
      </Box>

      <RangeBox>
        <Box
          width={`${widthStep}%`}
          height="4px"
          sx={{ background: "#FFDC22" }}
        />
      </RangeBox>
    </StepperCompBox>
  );
};
