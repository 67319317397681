import React from "react";
import { Box, Typography } from "@mui/material";
import { Translate } from "react-translated";
import ErrorIcon from "@mui/icons-material/Error";

export const ErrorMessage = ({ errorMessage, sx = {} }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "10px",
      }}
    >
      <ErrorIcon
        sx={{
          mr: 1,
          width: "20px",
          height: "20px",
          color: "primary.red",
          paddingTop: "2px",
        }}
      />
      <Typography
        variant="Body4"
        sx={{
          color: "primary.red",
          fontSize: { sm: "14px" },
          lineHeight: { xs: "20px" },
        }}
      >
        <Translate text={`${errorMessage}_${dynamicType}`} />
      </Typography>
    </Box>
  );
};
