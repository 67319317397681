import React from "react";
import { Typography, Box } from "@mui/material";
import { Translate } from "react-translated";

export const DialogOrderList = ({ arrayData }) => {
  return arrayData?.map((item) => (
    <Box key={item.order} sx={{ display: "flex", mt: 1, ml: 1 }}>
      <Box component="span" variant="Body5" sx={{ mr: 2, fontSize: "14px" }}>
        {item.order}
      </Box>
      <Typography variant="Body5" component="p" sx={{ whiteSpace: "pre-wrap" }}>
        <Translate text={`general_terms_of_service_content_${item.num}`} />
      </Typography>
    </Box>
  ));
};
