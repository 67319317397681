import React from "react";
import { FormControl, InputLabel, Select, styled } from "@mui/material";
import { Translate } from "react-translated";

const FormControlBox = styled(FormControl)(({ theme }) => ({
  border: "1px solid #F76448",
  color: "primary.grey",
  borderRadius: "8px",
  outline: "none",
  height: "40px",
  width: "30%",
  boxSizing: "border-box",

  [theme.breakpoints.up("sm")]: {
    height: "44px",
  },
}));

export const FormControlComp = ({
  value,
  handleChange,
  label,
  name,
  getOption = null,
  labelId,
  id,
  sx,
}) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <FormControlBox fullWidth className="formControlBox" sx={sx} size="small">
      <InputLabel id={id} sx={{ p: "0 5px" }}>
        <Translate text={`${label}_${dynamicType}`} />
      </InputLabel>
      <Select
        name={name}
        value={value}
        label={<Translate text={`${label}_${dynamicType}`} />}
        onChange={handleChange}
        labelId={labelId}
        id={id}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 240,
            },
          },
        }}
      >
        {getOption}
      </Select>
    </FormControlBox>
  );
};
