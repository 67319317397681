import React from "react";
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setModalState } from "../../../features/modalStateSlice";
import { HomeUserHeader } from "../homeComp/HomeUserHeader";
import { HomeUserName } from "../homeComp/HomeUserName";
import { ButtonNext } from "../../common/ButtonNext";
import { HaveAccount } from "./../../common/HaveAccount";
import { Translate } from "react-translated";
import { CeckBoxWithText } from "../../common/CeckBoxWithText";

export const SuggestionDialog = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Box>
      <HomeUserHeader
        isHeader={false}
        srcImg={require(`./../images/homeUserHeaderOnline_${dynamicType}.png`)}
        sx={{
          wrapperBox: {
            minHeight: { xs: "168px", sm: "206px" },
            mx: { xs: 2, sm: 0 },
          },
          imgWrapperBox: {
            bottom: 0,
            top: { xs: "108px", sm: "146px" },
            px: { xs: "16px", sm: "21px" },
          },
        }}
      />
      <HomeUserName
        staticText={true}
        sx={{
          wrapperBox: {
            width: "175px",
            marginLeft: { xs: "164px", sm: "154px" },
            marginBottom: { xs: "12px", sm: "20px" },
          },
        }}
      />
      <Box sx={{ margin: { xs: "0 16px 36px", sm: "0" } }}>
        <Typography
          variant={isSmallScreen ? "Subtitle4" : "Subtitle2"}
          color="primary.main"
          sx={{ margin: "0 0 12px", display: "inline-block" }}
        >
          <Translate text={`suggest_registration_1_${dynamicType}`} />
        </Typography>
        <CeckBoxWithText text="suggest_registration_2" />
        <CeckBoxWithText text="suggest_registration_3" />
        <CeckBoxWithText text="suggest_registration_4" />
        <Divider
          variant="fullWidth"
          sx={{ backgroundColor: "primary.lightGrey", margin: "10px 0 16px" }}
        />
        <Typography
          variant="Subtitle3"
          sx={{ textAlign: "center", display: "block" }}
        >
          <Translate text={`suggest_registration_5_${dynamicType}`} />
        </Typography>
        <Typography
          variant="Subtitle3"
          sx={{ textAlign: "center", display: "block" }}
        >
          <Translate text={`suggest_registration_6_${dynamicType}`} />
        </Typography>
        <ButtonNext
          style={{ marginBottom: "12px", fontWeight: "500" }}
          text="Yes"
          onClick={() =>
            dispatch(
              setModalState({
                isOpen: true,
                type: "signup",
                isOpenSmallModal: false,
              })
            )
          }
        />
        <HaveAccount />
      </Box>
    </Box>
  );
};
