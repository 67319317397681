import React from "react";
import { Box } from "@mui/material";

export const ExtraAge = () => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <img
        src={require(`./../../images/extraAge_${dynamicType}.png`)}
        alt="ExtraGender"
        style={{ width: "100%" }}
      />
    </Box>
  );
};
