import React from "react";
import { Typography } from "@mui/material";
import { Translate } from "react-translated";

export const ButtonBack = ({ disabled = false, onClick = null }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Typography
      onClick={onClick}
      disabled={disabled}
      sx={{
        width: "fit-content",
        textTransform: "none",
        textAlign: "center",
        fontWeight: "500",
        lineHeight: "24px",
        fontSize: "16px",
        color: "primary.main",
        cursor: "pointer",
        margin: "0 auto 32px",
        px: 2,
      }}
    >
      <Translate text={`Back_${dynamicType}`} />
    </Typography>
  );
};
