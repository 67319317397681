import React from "react";
import { GeneralTermsOfServiceDialog } from "../GeneralTermsOfServiceDialog";
import { PrivacyStatementDialog } from "../PrivacyStatementDialog";
import { SuggestionDialog } from "../../home/dialogComp/SuggestionDialog";
import { LoginDialog } from "../../home/dialogComp/LoginDialog";
import { SignupDialog } from "../../home/dialogComp/SignupDialog";

export const DialogContentComp = ({ type }) => {
  return (
    <>
      {type === "terms" && <GeneralTermsOfServiceDialog />}
      {type === "privacy" && <PrivacyStatementDialog />}
      {type === "suggestion" && <SuggestionDialog />}
      {type === "signup" && <SignupDialog />}
      {type === "login" && <LoginDialog />}
    </>
  );
};
