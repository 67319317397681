import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import comments from "./../components/home/images/comments.png";
import fund from "./../components/home/images/fund.png";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import VideocamIcon from "@mui/icons-material/Videocam";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";

export const homeUserCartData = [
  {
    id: 1,
    text: "Just playful 😏",
  },
  {
    id: 2,
    text: "mmm...what you think of the black and white shoots?",
  },
  {
    id: 3,
    text: "This pic should go viral, guys ",
  },
];

export const homeUserIconsData = [
  {
    id: 0,
    text: 569,
    Icon: FavoriteBorderIcon,
  },
  {
    id: 1,
    srcImg: comments,
    text: 34,
  },
  {
    id: 2,
    srcImg: fund,
  },
];

export const homeUserHeaderIconsData = [
  {
    id: 0,
    Icon: PhotoSizeSelectActualIcon,
    text: 6,
  },
  {
    id: 1,
    Icon: VideocamIcon,
    text: 21,
  },
  {
    id: 2,
    Icon: FavoriteIcon,
    text: "3.6k",
  },
  {
    id: 3,
    Icon: PersonIcon,
    text: "1k",
  },
];

export const onlineUsersImages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const leftMenuData = [
  {
    id: 0,
    Icon: PersonIcon,
    text: "home_content_5",
  },
  {
    id: 1,
    Icon: ExploreOutlinedIcon,
    text: "home_content_6",
  },
  {
    id: 2,
    Icon: HomeRoundedIcon,
    text: "home_content_7",
  },
  {
    id: 3,
    Icon: NotificationsNoneIcon,
    text: "home_content_8",
  },
  {
    id: 4,
    Icon: MailOutlineRoundedIcon,
    text: "home_content_9",
  },
];
